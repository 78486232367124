import { MTP_BASE_URL, ELEMENTAR_ANALYSIS_BASE_URL } from 'apiRoutes';
import { Auth } from 'aws-amplify';
import {
  http,
  handleErrors,
  handleOtherErrors
} from '../../../services/http-wrapper';

//=========GET ALL COMPANY LIST=============//
export const ApiGetCompany = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/usuario_empresa/read`,
      user.getIdToken().getJwtToken()
    );
    // console.log(user.getIdToken().getJwtToken());
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//=============GET COMPANY BY ID==================//
export const ApiGetCompanyById = async ({ id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/company/get/${id}`,
      user.getIdToken().getJwtToken(),
      id
    );
    // console.log(user.getIdToken().getJwtToken());
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//=========SAVE COMPANY DETAILS=============//
export const ApiSaveCompany = async (data) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${MTP_BASE_URL}/records/company/save`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//============COMPANY DELETE ROW================//
export const ApiDeleteCadastrarEmpresa = async ({ id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${MTP_BASE_URL}/records/company/delete/${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleOtherErrors('cadastros', error.response.status);
    handleErrors(error);
  }
};

//============COMPANY CNPJ DATA================//
export const ApiCadastrarEmpresaCnpj = async ({ cnpj }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/receita_cnpj/${cnpj}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//============CLIENT CNPJ DATA================//
export const ApiCadastrarClientCnpj = async ({ cnpj }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/receita_cnpj/${cnpj}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//===GET ALL COMPANY LIST REGISTERED IN MAKE VIEW ===//
export const ApiGetCadEmpresaMakeView = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_ANALYSIS_BASE_URL}/table/list/mvempresasgeral?page=1&per_page=10`,
      user.getIdToken().getJwtToken()
    );

    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//=== DELETE COMPANY ROW REGISTERED IN MAKE VIEW  ===//
export const ApiDeleteCadEmpresaMakeView = async ({ id_empresa }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${ELEMENTAR_ANALYSIS_BASE_URL}/table/delete/mvempresasgeral/${id_empresa}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//===CREATE NEW COMPANY REGISTERED IN MAKE VIEW ===//
export const ApiSaveCadEmpresaMakeView = async ({
  id_cliente,
  id_empresa,
  id_matriz,
  data_ini,
  data_fim
}) => {
  try {
    let data = {
      id_cliente,
      id_empresa,
      id_matriz,
      data_ini,
      data_fim
    };
    let user = await Auth.currentSession();
    const api = await http.post(
      `${ELEMENTAR_ANALYSIS_BASE_URL}/table/create/mvempresasgeral`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//===UPDATE NEW COMPANY REGISTERED IN MAKE VIEW ===//
export const ApiUpdateCadEmpresaMakeView = async ({
  id_cliente,
  id_matriz,
  data_ini,
  data_fim
}) => {
  try {
    let data = {
      id_cliente,
      id_matriz,
      data_ini,
      data_fim
    };
    let user = await Auth.currentSession();
    const api = await http.put(
      `${ELEMENTAR_ANALYSIS_BASE_URL}/table/update/mvempresasgeral/${id_matriz}`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

// CALENDAR ACTIVITIES
export const ApiGetCalendarActivities = async ({ companyId }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/calendario/list?id_empresa=${companyId}&page=1&per_page=100`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiPostCalendarActivities = async ({
  id_empresa,
  nome_atividade,
  descricao,
  atividade_padrao,
  data_atividade,
  status,
  email,
  lembrete
}) => {
  try {
    let data = {
      id_empresa,
      nome_atividade,
      descricao,
      atividade_padrao,
      data_atividade,
      status,
      email,
      lembrete
    };
    let user = await Auth.currentSession();
    const api = await http.post(
      `${MTP_BASE_URL}/calendario/create`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiUpdateCalendarActivities = async ({
  id_calendario_empresa,
  nome_atividade,
  descricao,
  atividade_padrao,
  data_atividade,
  status,
  email,
  lembrete
}) => {
  try {
    let data = {
      nome_atividade,
      descricao,
      atividade_padrao,
      data_atividade,
      status,
      email,
      lembrete
    };
    let user = await Auth.currentSession();
    const api = await http.put(
      `${MTP_BASE_URL}/calendario/update/${id_calendario_empresa}`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiDeleteCalendarActivities = async ({
  id_calendario_empresa
}) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${MTP_BASE_URL}/calendario/delete/${id_calendario_empresa}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};
