import React, { useEffect, useState, useRef } from 'react';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import Logo from '../../../assets/ColoredLogo_Dark.png';
import UserMenu from '../UserMenu/UserMenu';
import {
  getUserAuthorized,
  recordUserAuthSession,
  userdataSelector
} from 'features/userFeatures/userdata.store';
import { useDispatch, useSelector } from 'react-redux';
import {
  ELEMENTARHOME,
  PORTALHOME,
  NOTAAVULSAHOME,
  MAKEFORWARDINTEGRATIONS,
  SUBIDADEDOCUMENTOS,
  SOLICITARDOCUMENTOS,
  BASELEGAL,
  DEFINIRCATEGORIAS,
  CADASTROSBASICOS,
  MTPHOME,
  MAKEVIEW,
  MAKEVIEWFILES
} from '../../../Routes';
import { Link } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import './style.css';
import '../../../styles/index.css';
import theme from 'theme';
import {
  Navbar,
  Avatar,
  UserName,
  CompanyName
} from '../../../styledComponentsStyles';
import styledtheme from '../../../styledThemeOn';

function PortalFrame(props) {
  const effectMonitor1 = useRef(false);
  /*componente que abrange o header de todas as páginas e
  a barra lateral. Funciona como um "arco" para as páginas 
  que "envolve". Recebe só dois tipos distintos de children:
  A página "PortalHome" (que é a home page do portal MTW) 
  renderizada dentro desse "arco" e as páginas de serviços
  da MTW (Make Forward, Make BI, etc) também renderizadas
  dentro desse arco.
  As props são:
  props.header: booleano que diz quando a barra lateral (sidebar) está no modo básico (sem todas as opções)
  props.sidebar: booleano que...
  props.shrunken: booleano que diz quando a barra lateral (sidebar) está no modo encolhido
  props.titulo: ...
  props.children*/
  const { window } = props;
  const { userdata, userauth } = useSelector(userdataSelector);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userCode, setUserCode] = useState('');
  const [authorized, setAuthorized] = useState({
    catg: false,
    elm: false,
    idGroup: false,
    mbi: false,
    mtp: false,
    nfAv: false,
    solDoc: false,
    upDoc: false,
    cadDad: false,
    mia: false,
    user: false,
    erp: false
  });
  /*Authorization schema*/

  //--------------------API--------------------
  let dispatch = useDispatch();

  useEffect(() => {
    const incomingUser = async () => {
      let user = await userdata.Id;
      setUserCode(user);
    };
    incomingUser();
  }, [userdata]);
  /*Gets private data from userdata api*/

  useEffect(() => {
    if (userCode?.length > 0) {
      if (effectMonitor1.current === false) {
        dispatch(getUserAuthorized(userCode));
        effectMonitor1.current = true;
      }
    }
  }, [userCode]);
  /*As the userCode state changes, it is used to dispatch userauth api*/

  useEffect(() => {
    const hardCodedChecker = () => {
      if (Object.keys(userdata).length !== 0) {
        let checkAraucaria = userdata.Email === 'admaraucaria@maketheway.tech';
        return checkAraucaria;
      }
    };

    if (userauth?.itens) {
      let maketheprice = userauth?.itens[0];
      let elementar = userauth?.itens[1];
      let upload = userauth?.itens[2];
      let solicitar = userauth?.itens[3];
      let categorias = userauth?.itens[4];
      let makebi = userauth?.itens[5];
      let makeforward = userauth?.itens[6];
      let cadastrosbasicos = userauth?.itens[7];
      let erp = userauth?.itens[8];
      setAuthorized({
        catg: categorias ? categorias['Catg'] : false,
        elm: elementar ? elementar['Elm'] : false,
        idGroup: true,
        mbi: makebi ? makebi['Mbi'] : false,
        mtp: maketheprice ? maketheprice['Mtp'] : false,
        nfAv: makeforward ? makeforward['NfAv'] : false,
        solDoc: solicitar ? solicitar['SolDoc'] : false,
        upDoc: upload ? upload['UpDoc'] : false,
        cadDad: cadastrosbasicos ? cadastrosbasicos['CadDad'] : false,
        mia: true,
        user: userauth?.User ? true : false,
        erp: erp ? erp['Erp'] : hardCodedChecker()
      });
    }
  }, [userauth]);
  /*Pages links autorization schema*/

  useEffect(() => {
    if (userauth) {
      dispatch(recordUserAuthSession(userauth));
    }
  }, [userauth]);
  /*Records on state management tool the userauth data*/

  //--------------------Handlers--------------------
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted
    const handleLoadImage = async () => {
      try {
        const url = await Storage.get('CompanyLogo.png', {
          level: 'private',
          region: 'us-east-1'
        });
        if (isMounted) {
          setLogoFile(url);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    handleLoadImage(); // Invoke the asynchronous function
    // Cleanup function
    return () => {
      isMounted = false; // Update the flag when the component unmounts
    };
  }, []); // Dependency array, pass dependencies if needed

  //--------------------JSX/CSS--------------------
  const drawerWidth = !props.shrunken ? 250 : props.shrunken ? 50 : 250;

  const [drawerHeight, setDrawerHeight] = useState(props.vp - 61);

  useEffect(() => {
    if (props.scrollY < 61) {
      setDrawerHeight(props.vp - (61 - props.scrollY));
    } else if (props.scrollY >= 61) {
      setDrawerHeight(props.vp);
    }
  }, [props.scrollY]);

  const mainLogo = {
    mt: 2
  };

  const listItemIcon = {
    color: theme.palette.text.voidTitle,
    width: '18px'
  };

  const listItemWhenShrunken = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const listItemIconCenter = {
    color: theme.palette.text.voidTitle,
    width: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const logoImg = {
    width: '182.45px',
    height: '24px'
  };

  const rootStyle = {
    zIndex: 100,
    display: 'flex',
    width: '100%'
  };

  const menuWrapperStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  };

  const imgClassStyles = {
    width: '100%',
    height: '20%',
    objectFit: 'cover'
  };

  const navbarLeftStyles = {
    display: 'flex'
  };

  const avatarListStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  };

  const logoStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  };

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const drawer = (
    <div>
      {/*home page logo*/}
      {!props.sidebar ? (
        <Toolbar sx={mainLogo}>
          <Link to={PORTALHOME}>
            <img src={Logo} style={logoImg} alt="Make the Way" />
          </Link>
        </Toolbar>
      ) : null}
      {/*sidebar accordion closed*/}
      {props.shrunken ? (
        <List className="drawer">
          <Box sx={{ mt: 1 }}>
            <ListItem sx={listItemWhenShrunken} button key="Início">
              <ListItemIcon sx={listItemIconCenter}>
                <HomeRoundedIcon />
              </ListItemIcon>
            </ListItem>
          </Box>
          <Divider sx={{ mt: 2.2, mb: 2.2 }} />
          <Box sx={{ mt: 1 }}>
            <ListItem sx={listItemWhenShrunken} button key="Upload">
              <ListItemIcon sx={listItemIconCenter}>
                <CloudUploadIcon />
              </ListItemIcon>
            </ListItem>
          </Box>
          <Box sx={{ mt: 1 }}>
            <ListItem sx={listItemWhenShrunken} button key="Meus Documentos">
              <ListItemIcon sx={listItemIconCenter}>
                <SnippetFolderIcon />
              </ListItemIcon>
            </ListItem>
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box>
            <ListItem sx={listItemWhenShrunken} key="Aplicações">
              <ListItemIcon sx={listItemIconCenter}>
                <AppsRoundedIcon />
              </ListItemIcon>
            </ListItem>
          </Box>
        </List>
      ) : !props.shrunken ? (
        <List className="drawer">
          {/*sidebar accordion open*/}
          <Box>
            <Link className="list-nav-link" to={PORTALHOME}>
              <ListItem button key="Início">
                <ListItemIcon sx={listItemIcon}>
                  <HomeRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Início" />
              </ListItem>
            </Link>
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
          {authorized.upDoc && (
            <>
              <Box sx={{ mt: 1 }}>
                <Link className="list-nav-link" to={SUBIDADEDOCUMENTOS}>
                  <ListItem button key="Upload">
                    <ListItemIcon sx={listItemIcon}>
                      <CloudUploadIcon />
                    </ListItemIcon>
                    <ListItemText primary="Upload" />
                  </ListItem>
                </Link>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Link className="list-nav-link" to={MAKEVIEWFILES}>
                  <ListItem button key="Meus Documentos">
                    <ListItemIcon sx={listItemIcon}>
                      <SnippetFolderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Meus Documentos" />
                  </ListItem>
                </Link>
              </Box>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </>
          )}
          <Box>
            <ListItem key="Aplicações">
              <ListItemIcon sx={listItemIcon}>
                <AppsRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Aplicações" />
            </ListItem>
          </Box>
          {/*list of pages*/}
          {authorized.user ? (
            <>
              {authorized.mtp ? (
                <Box>
                  <Link
                    className="list-nav-link"
                    to={MTPHOME}
                    onClick={() => window.location.reload(false)}
                    replace
                  >
                    <ListItem button key="CAT 42">
                      <ListItemIcon />
                      <ListItemText primary="CAT 42" />
                    </ListItem>
                  </Link>
                </Box>
              ) : null}
              {authorized.elm ? (
                <Box>
                  <Link
                    className="list-nav-link"
                    to={ELEMENTARHOME}
                    onClick={() => window.location.reload(false)}
                    replace
                  >
                    <ListItem button key="Make the Price">
                      <ListItemIcon />
                      <ListItemText primary="Make the Price" />
                    </ListItem>
                  </Link>
                </Box>
              ) : null}
              <Box>
                <Link className="list-nav-link" to={MAKEVIEW} replace>
                  <ListItem button key="Make View">
                    <ListItemIcon />
                    <ListItemText primary="Make View" />
                  </ListItem>
                </Link>
              </Box>
              {authorized.cadDad ? (
                <Box>
                  <Link
                    className="list-nav-link"
                    to={CADASTROSBASICOS}
                    onClick={() => window.location.reload(false)}
                    replace
                  >
                    <ListItem button key="Cadastros Básicos">
                      <ListItemIcon />
                      <ListItemText primary="Cadastros Básicos" />
                    </ListItem>
                  </Link>
                </Box>
              ) : null}
              {authorized.nfAv ? (
                <Box>
                  <Link
                    className="list-nav-link"
                    to={NOTAAVULSAHOME}
                    onClick={() => window.location.reload(false)}
                    replace
                  >
                    <ListItem button key="Make Forward">
                      <ListItemIcon />
                      <ListItemText primary="Make Forward" />
                    </ListItem>
                  </Link>
                </Box>
              ) : null}
              {authorized.solDoc ? (
                <Box>
                  <Link
                    className="list-nav-link"
                    to={SOLICITARDOCUMENTOS}
                    onClick={() => window.location.reload(false)}
                    replace
                  >
                    <ListItem button key="Solicitar Documentos">
                      <ListItemIcon />
                      <ListItemText primary="Solicitar Documentos" />
                    </ListItem>
                  </Link>
                </Box>
              ) : null}
              {authorized.solDoc ? (
                <Box>
                  <Link
                    className="list-nav-link"
                    to={BASELEGAL}
                    onClick={() => window.location.reload(false)}
                    replace
                  >
                    <ListItem button key="Base Legal">
                      <ListItemIcon />
                      <ListItemText primary="Base Legal" />
                    </ListItem>
                  </Link>
                </Box>
              ) : null}
              {authorized.catg ? (
                <Box>
                  <Link
                    className="list-nav-link"
                    to={DEFINIRCATEGORIAS}
                    onClick={() => window.location.reload(false)}
                    replace
                  >
                    <ListItem button key="Definir Categorias">
                      <ListItemIcon />
                      <ListItemText primary="Definir Categorias" />
                    </ListItem>
                  </Link>
                </Box>
              ) : null}
              {authorized.nfAv ? (
                <Box>
                  <Link
                    className="list-nav-link"
                    to={MAKEFORWARDINTEGRATIONS}
                    onClick={() => window.location.reload(false)}
                    replace
                  >
                    <ListItem button key="Integrações">
                      <ListItemIcon />
                      <ListItemText primary="Integrações" />
                    </ListItem>
                  </Link>
                </Box>
              ) : null}
              {authorized.erp && (
                <Box>
                  <a
                    className="pseudolink"
                    href="http://54.89.110.7:3000/admin/dashboard"
                  >
                    <ListItem button key="Integrações">
                      <ListItemIcon />
                      <ListItemText primary="ERP" />
                    </ListItem>
                  </a>
                </Box>
              )}
            </>
          ) : null}
        </List>
      ) : null}
    </div>
  );

  /*Bloco que renderiza o PortalFrame, que abrange o header, a sidebar
  e um pseudo header centralizado na página de boas-vindas*/
  return (
    <div style={rootStyle}>
      {/*Pseudo header centralizado na página de boas-vindas com a logo
        da empresa-cliente, visível somente quando o PortalFrame não está 
        no modo lite*/}
      {!props.header && !props.sidebar ? (
        <AppBar
          color="transparent"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            padding: '0',
            margin: '0',
            listStyle: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            height: '68px',
            boxShadow: 0,
            backgroundColor: '#fff',
            border: '1px solid #EAECF0'
          }}
        >
          <Toolbar sx={menuWrapperStyles}>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <div>
              {loading ? (
                <h6>Uploading...</h6>
              ) : (
                <img style={imgClassStyles} src={logoFile} alt="logo" />
              )}
            </div>
            <div>
              <Typography
                variant="h5"
                sx={{ fontSize: 22 }}
                noWrap
                component="div"
              >
                {matches ? '' : props.titulo}
              </Typography>
            </div>
            <div className="avatar">
              <UserMenu setLogoFile={setLogoFile} />
              <div className="avatarList">
                <div>
                  <p style={{ margin: 0, fontSize: matches ? 12 : 16 }}>
                    {userdata?.UserName}
                  </p>
                </div>
                <div>
                  <p style={{ margin: 0, fontSize: matches ? 10 : 14 }}>
                    {userdata?.Cliente}
                  </p>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      ) : props.header ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          {/*header*/}
          <div
            style={{
              boxShadow: 0,
              backgroundColor: '#FFFFFF',
              border: '1px solid #EAECF0',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Navbar>
              {/*logo segment*/}
              <div style={navbarLeftStyles}>
                <div style={logoStyles}>
                  <div>
                    <Link to={PORTALHOME}>
                      <img src={Logo} style={logoImg} alt="Make the Way" />
                    </Link>
                  </div>
                </div>
              </div>
              {/*segment that displays profile/icon/name*/}
              <div>
                <Avatar>
                  <UserMenu />
                  <div syle={avatarListStyles}>
                    <div>
                      <UserName styledtheme={styledtheme}>
                        {userdata.UserName}
                      </UserName>
                    </div>
                    <div>
                      <CompanyName styledtheme={styledtheme}>
                        {userdata.Cliente}
                      </CompanyName>
                    </div>
                  </div>
                </Avatar>
              </div>
            </Navbar>
          </div>
        </div>
      ) : null}
      {/*Menu lateral das páginas de serviços da MTW*/}
      {props.sidebar && (
        <div
          className="sidebarexpand"
          style={{ height: drawerHeight, width: drawerWidth }}
        >
          {drawer}
        </div>
      )}
      {/*Menu lateral à esquerda fixo (na página de boas-vindas)*/}
      {!props.header && !props.sidebar && (
        <Box
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              overflowY: 'hidden',
              '&:hover': {
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '10px'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius: '5px',
                  border: '2px solid #f1f1f1'
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555'
                }
              }
            }
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth
              }
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      )}
      {/*unknown*/}
      {!props.header && !props.sidebar ? (
        <Box
          sx={{
            marginTop: '68px',
            padding: '24px 24px 24px 24px',
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            height: `calc(100vh - 68px)`,
            maxHeight: `calc(100vh - 68px)`,
            background: theme.palette.background.outmost
          }}
        >
          {props.children}
        </Box>
      ) : null}
    </div>
  );
}

export default PortalFrame;
