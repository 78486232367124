import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ApiGetUploadedFiles,
  ApiGetUploadedFilesNoType,
  ApiSaveUploadedFiles,
  ApiDeleteFile,
  ApiGetTypeOptions,
  ApiCreateExecution,
  ApiGetExecution,
  ApiGetDocumentTypes,
  ApiPostDocumentType,
  ApiGetDocumentsByType as ApiGetDocumentsByType,
  ApiPostDLMTag,
  ApiGetDLMTags,
  ApiPostDLMWorkflow,
  ApiGetDLMWorkflow,
  ApiPostDLMTagDocument,
  ApiGetDLMListKeyword,
  ApiPostDLMKeyword,
  ApiPostDLMKeywordDocument,
  ApiPostDLMValidateTag,
  ApiDeleteDLMDoc,
  ApiDeleteDLMKeyword,
  ApiPostDLMTagWarning,
  ApiPutDLMUpdateTagWorkflow,
  ApiGetDLMTagWarning,
  ApiDeleteDLMTag,
  ApiDeleteDLMTagWorkflow,
  ApiPostDLMDocumentVersion,
  ApiPutDocumentApproval,
  ApiPostNewDocument,
  ApiPostDLMAttachDocuments,
  ApiDeleteDLMAttachDocuments,
  ApiPostDLMValidateTagEmail,
  ApiPostDLMAttachFiles,
  ApiUpoloadFlightData,
  ApiUpoloadPriceReport,
  ApiChatGptDlmProcedures
} from './subidaDeDocumentos.api';
import { createAction } from '@reduxjs/toolkit';

export const resetDLMDocTypeId = createAction('dlm/resetdoctypeid');

export const recordDLMDocTypeData = createAction(
  'dlm/recorddoctypeid',
  (data) => ({
    payload: data
  })
);

export const recordDLMDocsSelected = createAction(
  'dlm/recorddocsselected',
  (data) => ({
    payload: data
  })
);
//usada

export const recordDLMDocVersionData = createAction(
  'dlm/recorddocversiondata',
  (data) => ({
    payload: data
  })
);

export const getUploadedFiles = createAsyncThunk(
  'api/files',
  async ({
    id_empresa,
    tipo_nota,
    tipo_do_arquivo,
    error_message,
    nome_do_arquivo,
    page,
    per_page,
    data_inicio,
    data_fim
  }) => {
    let response = await ApiGetUploadedFiles({
      id_empresa,
      tipo_nota,
      tipo_do_arquivo,
      error_message,
      nome_do_arquivo,
      page,
      per_page,
      data_inicio,
      data_fim
    });
    return response;
  }
);

export const getUploadedFilesNoType = createAsyncThunk(
  'api/allfiles',
  async ({ id, page, per_page }) => {
    let response = await ApiGetUploadedFilesNoType({
      id,
      page,
      per_page
    });
    return response;
  }
);

export const getTypeOptions = createAsyncThunk('api/types', async (id) => {
  let response = await ApiGetTypeOptions(id);
  return response;
});

export const saveUploadedFiles = createAsyncThunk(
  'api/save',
  async (saveData) => {
    let response = await ApiSaveUploadedFiles(saveData);
    return response;
  }
);

export const deleteUploadedFiles = createAsyncThunk(
  'api/delete',
  async (ids) => {
    let response = await ApiDeleteFile(ids);
    return response;
  }
);

export const createExecution = createAsyncThunk(
  'api/createexec',
  async (executionData) => {
    let response = await ApiCreateExecution(executionData);
    return response;
  }
);

export const getExecution = createAsyncThunk('api/getexec', async (id) => {
  let response = await ApiGetExecution(id);
  return response;
});

// DLM Document Lifecycle Manager
export const postDocumentType = createAsyncThunk(
  'api/postdlmdocumenttype',
  async ({ company_id, extensao, nome, sigla }) => {
    let response = await ApiPostDocumentType({
      company_id,
      extensao,
      nome,
      sigla
    });
    return response;
  }
);

export const getDocumentTypes = createAsyncThunk(
  'api/getdlmdocumenttypes',
  async (cnpj) => {
    let response = await ApiGetDocumentTypes(cnpj);
    return response;
  }
);

export const postDLMDocument = createAsyncThunk(
  'api/postnewdlmdocument',
  async ({ key, document_type_id, descricao }) => {
    let response = await ApiPostNewDocument({
      key,
      document_type_id,
      descricao
    });
    return response;
  }
);

export const getDocumentsByType = createAsyncThunk(
  'api/getdlmdocumentsbytype',
  async (document_type_id) => {
    let response = await ApiGetDocumentsByType(document_type_id);
    return response;
  }
);

export const getDocsByTypeForAttach = createAsyncThunk(
  'api/getdlmdocsbytypeforattach',
  async (document_type_id) => {
    let response = await ApiGetDocumentsByType(document_type_id);
    return response;
  }
);

export const deleteDLMDoc = createAsyncThunk('api/deletedlmdoc', async (id) => {
  let response = await ApiDeleteDLMDoc(id);
  return response;
});

// attach tags
export const postDLMTagDocument = createAsyncThunk(
  'api/postdlmtagdocument',
  async ({ document_id, tag_id }) => {
    let response = await ApiPostDLMTagDocument({
      document_id,
      tag_id
    });
    return response;
  }
);

export const postDLMValidateTag = createAsyncThunk(
  'api/postdlmvalidatetag',
  async ({ tag_id, document_type_id, document_id, validation }) => {
    let response = await ApiPostDLMValidateTag({
      tag_id,
      document_type_id,
      document_id,
      validation
    });
    return response;
  }
);

export const postDLMKeyword = createAsyncThunk(
  'api/postdlmkeyword',
  async () => {
    let response = await ApiPostDLMKeyword();
    return response;
  }
);

export const getDLMKeywords = createAsyncThunk(
  'api/getdlmkeywords',
  async ({ page, per_page }) => {
    let response = await ApiGetDLMListKeyword({
      page,
      per_page
    });
    return response;
  }
);

// attach keyword
export const postDLMKeywordDocument = createAsyncThunk(
  'api/postdlmkeyworddocument',
  async () => {
    let response = await ApiPostDLMKeywordDocument();
    return response;
  }
);

export const deleteDLMKeyword = createAsyncThunk(
  'api/deletedlmkeyword',
  async (id) => {
    let response = await ApiDeleteDLMKeyword(id);
    return response;
  }
);

export const postDLMDocumentVersion = createAsyncThunk(
  'api/postdlmvalidatetag',
  async ({ key, latest_document_id, versao }) => {
    let response = await ApiPostDLMDocumentVersion({
      key,
      latest_document_id,
      versao
    });
    return response;
  }
);

export const putDLMDocumentApproval = createAsyncThunk(
  'api/putdlmdocapproval',
  async ({ document_id, status }) => {
    let response = await ApiPutDocumentApproval({ document_id, status });
    return response;
  }
);

export const postDLMValidateTagEmail = createAsyncThunk(
  'api/postdlmvalidatetagemail',
  async ({ list_emails, email_subject, email_body }) => {
    let response = await ApiPostDLMValidateTagEmail({
      list_emails,
      email_subject,
      email_body
    });
    return response;
  }
);

// DLM Document Lifecycle Manager Workflow
export const postDLMTag = createAsyncThunk(
  'api/postdlmtag',
  async ({ name, color, company_id, document_type_id }) => {
    let response = await ApiPostDLMTag({
      name,
      color,
      company_id,
      document_type_id
    });
    return response;
  }
);

export const getDLMTags = createAsyncThunk(
  'api/getdlmtags',
  async (document_type_id) => {
    let response = await ApiGetDLMTags(document_type_id);
    return response;
  }
);

export const deleteDLMTag = createAsyncThunk('api/deletedlmtag', async (id) => {
  let response = await ApiDeleteDLMTag(id);
  return response;
});

export const postDLMWorkflow = createAsyncThunk(
  'api/postdlmworkflow',
  async ({ document_type_id, tag_orig_id, tag_dest_id, validation }) => {
    let response = await ApiPostDLMWorkflow({
      document_type_id,
      tag_orig_id,
      tag_dest_id,
      validation
    });
    return response;
  }
);

export const getDLMWorkflow = createAsyncThunk(
  'api/getdlmworkflow',
  async (document_type_id) => {
    let response = await ApiGetDLMWorkflow(document_type_id);
    return response;
  }
);

export const deleteDLMTagWorkflow = createAsyncThunk(
  'api/deletedlmworkflow',
  async (id) => {
    let response = await ApiDeleteDLMTagWorkflow(id);
    return response;
  }
);

export const postDLMTagWarning = createAsyncThunk(
  'api/postdlmtagwarning',
  async ({ tag_id, email, aviso }) => {
    let response = await ApiPostDLMTagWarning({ tag_id, email, aviso });
    return response;
  }
);

export const putDLMUpdateTagWorkflow = createAsyncThunk(
  'api/putdlmupdatetagworkflow',
  async ({ id, id_tag_orig, id_tag_dest }) => {
    let response = await ApiPutDLMUpdateTagWorkflow({
      id,
      id_tag_orig,
      id_tag_dest
    });
    return response;
  }
);

export const getDLMTagWarning = createAsyncThunk(
  'api/getdlmtagwarning',
  async (tag_id) => {
    let response = await ApiGetDLMTagWarning(tag_id);
    return response;
  }
);

//Anexos
export const postDLMAttachDocuments = createAsyncThunk(
  'api/postdlmattachdocuments',
  async ({ id_documento, id_anexo }) => {
    let response = await ApiPostDLMAttachDocuments({
      id_documento,
      id_anexo
    });
    return response;
  }
);

export const deleteDLMAttachDocuments = createAsyncThunk(
  'api/deletedlmattachdocuments',
  async (idAnexo) => {
    let response = await ApiDeleteDLMAttachDocuments(idAnexo);
    return response;
  }
);

export const postDLMAttachFiles = createAsyncThunk(
  'api/postdlmattachfiles',
  async ({ id_documento, key }) => {
    let response = await ApiPostDLMAttachFiles({
      id_documento,
      key
    });
    return response;
  }
);

export const postFightData = createAsyncThunk(
  'api/uploadflightdata',
  async ({ file_name }) => {
    let response = await ApiUpoloadFlightData({ file_name });
    return response;
  }
);

export const postPriceReportData = createAsyncThunk(
  'api/uploadpricereport',
  async ({ file_name }) => {
    let response = await ApiUpoloadPriceReport({ file_name });
    return response;
  }
);

export const getGptResponse = createAsyncThunk(
  'api/chatgptresponse',
  async ({ bucket_name, folder_name, pergunta }) => {
    let response = await ApiChatGptDlmProcedures({
      bucket_name,
      folder_name,
      pergunta
    });
    return response;
  }
);

export const subidaDeDocumentosDataSlice = createSlice({
  name: 'subidadedocumentos',
  initialState: {
    // getUploadedFiles
    files: [],
    isLoadingFiles: false,
    // getUploadedFilesNoType
    allFiles: [],
    isLoadingAllFiles: false,
    // getTypeOptions
    types: [],
    isLoadingTypes: false,
    //saveUploadedFiles:
    isSavingFiles: false,
    savedFiles: false,
    //deleteUploadedFiles:
    isDeletingFiles: false,
    deletedFile: false,
    //createExecution:
    isCreatingExec: false,
    createdExec: {},
    //getExecution:
    execution: [],
    isLoadingExecution: false,
    //getListDocumentType
    listDocumentType: [],
    isLoadingListDocumentType: false,
    //postDocumentType
    isLoadingDocumentType: false,
    documentType: {},
    //getListDocumentId
    listDocumentsByType: [],
    isLoadingListDocumentsByType: false,
    //getListDocumentIdForAttach
    listDocsByTypeForAttach: [],
    isLoadingListDocsByTypeForAttach: false,
    //record and reset dlm doc type id:
    dlmDocumentTypeData: {},
    //record and reset dlm doc version data:
    dlmDocumentVersionData: [],
    dlmDocsSelected: '',
    //dlm post tag:
    isLoadingPostDLMTag: false,
    postedDLMTag: {},
    //dlm get tags:
    isLoadingDLMTags: false,
    dlmTags: [],
    //dlm post workflow:
    isLoadingPostDLMWorkflow: false,
    postedDLMWorkflow: {},
    //get dlm workflow:
    isLoadingDLMWorkflow: false,
    dlmWorkflow: [],
    //post dlm tag document:
    isLoadingPostDLMTagDocument: false,
    postedDLMTagDocument: {},
    //get dlm list keyword:
    isLoadingDLMListKeyword: false,
    dlmListKeyword: [],
    //post dlm create keyword:
    isLoadingPostDLMCreateKeyword: false,
    postedDLMCreateKeyword: {},
    //post dlm create keyword doc:
    isLoadingPostDLMCreateKeywordDoc: false,
    postedDLMCreateKeywordDoc: {},
    //post dlm validate tag:
    isLoadingPostDLMValidateTag: false,
    postedDLMValidateTag: {},
    //delete dlm doc:
    isDeletingDLMDoc: false,
    //delete dlm keyword:
    isDeletingDLMKeyword: false,
    //post dlm create tag warning:
    isLoadingPostDLMCreateTagWarning: false,
    postedDLMCreateTagWarning: {},
    //put dlm update tag workflow:
    isUpdatingTagWorkflow: false,
    updateTagWorkflow: [],
    //get dlm list tag warning:
    isLoadingDLMListTagWarning: false,
    dlmListTagWarning: [],
    //delete dlm tag:
    isDeletingDLMTag: false,
    //delete dlm tag workflows:
    isDeletingDLMTagWorkflows: false,
    // postDLMDocumentVersion:
    isLoadingPostDLMDocumentVersion: false,
    postedDLMDocumentVersion: {},
    //putDLMDocumentApproval:
    isPutDLMDocumentApproval: false,
    //postDLMDocument:
    isPostDLMDocument: false,
    postedDLMDocument: {},
    //postdlmattachdocuments:
    isLoadingPostDLMAttachDocuments: false,
    postedDLMAttachDocuments: {},
    //deletedlmattachdocuments:
    isDeletingDLMAttachDocuments: false,
    //postdlmattachfiles:
    isLoadingPostDLMAttachFiles: false,
    postedDLMAttachFiles: {},
    //postdlmvalidatetagemail:
    isLoadingPostDLMValidateTagEmail: false,
    postedDLMValidateTagEmail: {},
    //uploadflightdata
    uploadFlightDataResponse: [],
    uploadPriceReportDataResponse: [],
    //getchatgpt
    getGptDlmResposeDataResponse: []
  },
  reducers: {},
  extraReducers: {
    [recordDLMDocTypeData]: (state, action) => {
      state.dlmDocumentTypeData = action.payload;
    },
    [recordDLMDocVersionData]: (state, action) => {
      state.dlmDocumentVersionData = action.payload;
    },
    [recordDLMDocsSelected]: (state, action) => {
      state.dlmDocsSelected = action.payload;
    },
    [getUploadedFiles.pending]: (state) => {
      state.isLoadingFiles = 'loading';
    },
    [getUploadedFiles.fulfilled]: (state, action) => {
      state.isLoadingFiles = 'success';
      state.files = action.payload;
    },
    [getUploadedFiles.rejected]: (state) => {
      state.isLoadingFiles = 'failed';
    },
    [getUploadedFilesNoType.pending]: (state) => {
      state.isLoadingAllFiles = 'loading';
    },
    [getUploadedFilesNoType.fulfilled]: (state, action) => {
      state.isLoadingAllFiles = 'success';
      state.allFiles = action.payload;
    },
    [getUploadedFilesNoType.rejected]: (state) => {
      state.isLoadingAllFiles = 'failed';
    },
    [getTypeOptions.pending]: (state) => {
      state.isLoadingTypes = 'loading';
    },
    [getTypeOptions.fulfilled]: (state, action) => {
      state.isLoadingTypes = 'success';
      state.types = action.payload;
    },
    [getTypeOptions.rejected]: (state) => {
      state.isLoadingTypes = 'failed';
    },
    [saveUploadedFiles.pending]: (state) => {
      state.isSavingFiles = 'loading';
    },
    [saveUploadedFiles.fulfilled]: (state, action) => {
      state.isSavingFiles = 'success';
      state.savedFiles = action.payload;
    },
    [saveUploadedFiles.rejected]: (state) => {
      state.isSavingFiles = 'failed';
    },
    [deleteUploadedFiles.pending]: (state) => {
      state.isDeletingFiles = 'loading';
    },
    [deleteUploadedFiles.fulfilled]: (state, action) => {
      state.isDeletingFiles = 'success';
      state.deletedFile = action.payload;
    },
    [deleteUploadedFiles.rejected]: (state) => {
      state.isDeletingFiles = 'failed';
    },
    [createExecution.pending]: (state) => {
      state.isCreatingExec = 'loading';
    },
    [createExecution.fulfilled]: (state, action) => {
      state.isCreatingExec = 'success';
      state.createdExec = action.payload;
    },
    [createExecution.rejected]: (state) => {
      state.isCreatingExec = 'failed';
    },
    [getExecution.pending]: (state) => {
      state.isLoadingExecution = 'loading';
    },
    [getExecution.fulfilled]: (state, action) => {
      state.isLoadingExecution = 'success';
      state.execution = action.payload;
    },
    [getExecution.rejected]: (state) => {
      state.isLoadingExecution = 'failed';
    },
    [getDocumentTypes.pending]: (state) => {
      state.isLoadingListDocumentType = 'loading';
    },
    [getDocumentTypes.fulfilled]: (state, action) => {
      state.isLoadingListDocumentType = 'success';
      state.listDocumentType = action.payload;
    },
    [getDocumentTypes.rejected]: (state) => {
      state.isLoadingListDocumentType = 'failed';
    },
    [postDocumentType.pending]: (state) => {
      state.isLoadingDocumentType = 'loading';
    },
    [postDocumentType.fulfilled]: (state, action) => {
      state.isLoadingDocumentType = 'success';
      state.documentType = action.payload;
    },
    [postDocumentType.rejected]: (state) => {
      state.isLoadingDocumentType = 'failed';
    },
    [getDocumentsByType.pending]: (state) => {
      state.isLoadingListDocumentsByType = 'loading';
    },
    [getDocumentsByType.fulfilled]: (state, action) => {
      state.isLoadingListDocumentsByType = 'success';
      state.listDocumentsByType = action.payload;
    },
    [getDocumentsByType.rejected]: (state) => {
      state.isLoadingListDocumentsByType = 'failed';
    },
    [getDocsByTypeForAttach.pending]: (state) => {
      state.isLoadingListDocsByTypeForAttach = 'loading';
    },
    [getDocsByTypeForAttach.fulfilled]: (state, action) => {
      state.isLoadingListDocsByTypeForAttach = 'success';
      state.listDocsByTypeForAttach = action.payload;
    },
    [getDocsByTypeForAttach.rejected]: (state) => {
      state.isLoadingListDocsByTypeForAttach = 'failed';
    },
    [postDLMTag.pending]: (state) => {
      state.isLoadingPostDLMTag = 'loading';
    },
    [postDLMTag.fulfilled]: (state, action) => {
      state.isLoadingPostDLMTag = 'success';
      state.postedDLMTag = action.payload;
    },
    [postDLMTag.rejected]: (state) => {
      state.isLoadingPostDLMTag = 'failed';
    },
    [getDLMTags.pending]: (state) => {
      state.isLoadingDLMTags = 'loading';
    },
    [getDLMTags.fulfilled]: (state, action) => {
      state.isLoadingDLMTags = 'success';
      state.dlmTags = action.payload;
    },
    [getDLMTags.rejected]: (state) => {
      state.isLoadingDLMTags = 'failed';
    },
    [postDLMWorkflow.pending]: (state) => {
      state.isLoadingPostDLMWorkflow = 'loading';
    },
    [postDLMWorkflow.fulfilled]: (state, action) => {
      state.isLoadingPostDLMWorkflow = 'success';
      state.postedDLMWorkflow = action.payload;
    },
    [postDLMWorkflow.rejected]: (state) => {
      state.isLoadingPostDLMWorkflow = 'failed';
    },
    [getDLMWorkflow.pending]: (state) => {
      state.isLoadingDLMWorkflow = 'loading';
    },
    [getDLMWorkflow.fulfilled]: (state, action) => {
      state.isLoadingDLMWorkflow = 'success';
      state.dlmWorkflow = action.payload;
    },
    [getDLMWorkflow.rejected]: (state) => {
      state.isLoadingDLMWorkflow = 'failed';
    },
    [postDLMTagDocument.pending]: (state) => {
      state.isLoadingPostDLMTagDocument = 'loading';
    },
    [postDLMTagDocument.fulfilled]: (state, action) => {
      state.isLoadingPostDLMTagDocument = 'success';
      state.postedDLMTagDocument = action.payload;
    },
    [postDLMTagDocument.rejected]: (state) => {
      state.isLoadingPostDLMTagDocument = 'failed';
    },
    [getDLMKeywords.pending]: (state) => {
      state.isLoadingDLMListKeyword = 'loading';
    },
    [getDLMKeywords.fulfilled]: (state, action) => {
      state.isLoadingDLMListKeyword = 'success';
      state.dlmListKeyword = action.payload;
    },
    [getDLMKeywords.rejected]: (state) => {
      state.isLoadingDLMListKeyword = 'failed';
    },
    [postDLMKeyword.pending]: (state) => {
      state.isLoadingPostDLMCreateKeyword = 'loading';
    },
    [postDLMKeyword.fulfilled]: (state, action) => {
      state.isLoadingPostDLMCreateKeyword = 'success';
      state.postedDLMCreateKeyword = action.payload;
    },
    [postDLMKeyword.rejected]: (state) => {
      state.isLoadingPostDLMCreateKeyword = 'failed';
    },
    [postDLMKeywordDocument.pending]: (state) => {
      state.isLoadingPostDLMCreateKeywordDoc = 'loading';
    },
    [postDLMKeywordDocument.fulfilled]: (state, action) => {
      state.isLoadingPostDLMCreateKeywordDoc = 'success';
      state.postedDLMCreateKeywordDoc = action.payload;
    },
    [postDLMKeywordDocument.rejected]: (state) => {
      state.isLoadingPostDLMCreateKeywordDoc = 'failed';
    },
    [postDLMValidateTag.pending]: (state) => {
      state.isLoadingPostDLMValidateTag = 'loading';
    },
    [postDLMValidateTag.fulfilled]: (state, action) => {
      state.isLoadingPostDLMValidateTag = 'success';
      state.postedDLMValidateTag = action.payload;
    },
    [postDLMValidateTag.rejected]: (state) => {
      state.isLoadingPostDLMValidateTag = 'failed';
    },
    [deleteDLMDoc.pending]: (state) => {
      state.isDeletingDLMDoc = 'loading';
    },
    [deleteDLMDoc.fulfilled]: (state) => {
      state.isDeletingDLMDoc = 'success';
    },
    [deleteDLMDoc.rejected]: (state) => {
      state.isDeletingDLMDoc = 'failed';
    },
    [deleteDLMKeyword.pending]: (state) => {
      state.isDeletingDLMKeyword = 'loading';
    },
    [deleteDLMKeyword.fulfilled]: (state) => {
      state.isDeletingDLMKeyword = 'success';
    },
    [deleteDLMKeyword.rejected]: (state) => {
      state.isDeletingDLMKeyword = 'failed';
    },
    [postDLMTagWarning.pending]: (state) => {
      state.isLoadingPostDLMCreateTagWarning = 'loading';
    },
    [postDLMTagWarning.fulfilled]: (state, action) => {
      state.isLoadingPostDLMCreateTagWarning = 'success';
      state.postedDLMCreateTagWarning = action.payload;
    },
    [postDLMTagWarning.rejected]: (state) => {
      state.isLoadingPostDLMCreateTagWarning = 'failed';
    },
    [putDLMUpdateTagWorkflow.pending]: (state) => {
      state.isUpdatingTagWorkflow = 'loading';
    },
    [putDLMUpdateTagWorkflow.fulfilled]: (state, action) => {
      state.isUpdatingTagWorkflow = 'success';
      state.updateTagWorkflow = action.payload;
    },
    [putDLMUpdateTagWorkflow.rejected]: (state) => {
      state.isUpdatingTagWorkflow = 'failed';
    },

    [getDLMTagWarning.pending]: (state) => {
      state.isLoadingDLMListTagWarning = 'loading';
    },
    [getDLMTagWarning.fulfilled]: (state, action) => {
      state.isLoadingDLMListTagWarning = 'success';
      state.dlmListTagWarning = action.payload;
    },
    [getDLMTagWarning.rejected]: (state) => {
      state.isLoadingDLMListTagWarning = 'failed';
    },
    [deleteDLMTag.pending]: (state) => {
      state.isDeletingDLMTag = 'loading';
    },
    [deleteDLMTag.fulfilled]: (state) => {
      state.isDeletingDLMTag = 'success';
    },
    [deleteDLMTag.rejected]: (state) => {
      state.isDeletingDLMTag = 'failed';
    },
    [deleteDLMTagWorkflow.pending]: (state) => {
      state.isDeletingDLMTagWorkflows = 'loading';
    },
    [deleteDLMTagWorkflow.fulfilled]: (state) => {
      state.isDeletingDLMTagWorkflows = 'success';
    },
    [deleteDLMTagWorkflow.rejected]: (state) => {
      state.isDeletingDLMTagWorkflows = 'failed';
    },
    [postDLMDocumentVersion.pending]: (state) => {
      state.isLoadingPostDLMDocumentVersion = 'loading';
    },
    [postDLMDocumentVersion.fulfilled]: (state, action) => {
      state.isLoadingPostDLMDocumentVersion = 'success';
      state.postedDLMDocumentVersion = action.payload;
    },
    [postDLMDocumentVersion.rejected]: (state) => {
      state.isLoadingPostDLMDocumentVersion = 'failed';
    },
    [putDLMDocumentApproval.pending]: (state) => {
      state.isPutDLMDocumentApproval = 'loading';
    },
    [putDLMDocumentApproval.fulfilled]: (state) => {
      state.isPutDLMDocumentApproval = 'success';
    },
    [putDLMDocumentApproval.rejected]: (state) => {
      state.isPutDLMDocumentApproval = 'failed';
    },
    [postDLMDocument.pending]: (state) => {
      state.isPostDLMDocument = 'loading';
    },
    [postDLMDocument.fulfilled]: (state, action) => {
      state.isPostDLMDocument = 'success';
      state.postedDLMDocument = action.payload;
    },
    [postDLMDocument.rejected]: (state) => {
      state.isPostDLMDocument = 'failed';
    },
    [postDLMAttachDocuments.pending]: (state) => {
      state.isLoadingPostDLMAttachDocuments = 'loading';
    },
    [postDLMAttachDocuments.fulfilled]: (state, action) => {
      state.isLoadingPostDLMAttachDocuments = 'success';
      state.postedDLMAttachDocuments = action.payload;
    },
    [postDLMAttachDocuments.rejected]: (state) => {
      state.isLoadingPostDLMAttachDocuments = 'failed';
    },
    [deleteDLMAttachDocuments.pending]: (state) => {
      state.isDeletingDLMAttachDocuments = 'loading';
    },
    [deleteDLMAttachDocuments.fulfilled]: (state) => {
      state.isDeletingDLMAttachDocuments = 'success';
    },
    [deleteDLMAttachDocuments.rejected]: (state) => {
      state.isDeletingDLMAttachDocuments = 'failed';
    },
    [postDLMAttachFiles.pending]: (state) => {
      state.isLoadingPostDLMAttachFiles = 'loading';
    },
    [postDLMAttachFiles.fulfilled]: (state, action) => {
      state.isLoadingPostDLMAttachFiles = 'success';
      state.postedDLMAttachFiles = action.payload;
    },
    [postDLMAttachFiles.rejected]: (state) => {
      state.isLoadingPostDLMAttachFiles = 'failed';
    },
    [postDLMValidateTagEmail.pending]: (state) => {
      state.isLoadingPostDLMValidateTagEmail = 'loading';
    },
    [postDLMValidateTagEmail.fulfilled]: (state, action) => {
      state.isLoadingPostDLMValidateTagEmail = 'success';
      state.postedDLMValidateTagEmail = action.payload;
    },
    [postDLMValidateTagEmail.rejected]: (state) => {
      state.isLoadingPostDLMValidateTagEmail = 'failed';
    },
    [postFightData.pending]: () => {
      null;
    },
    [postFightData.fulfilled]: (state, action) => {
      state.uploadFlightDataResponse = action.payload;
    },
    [postFightData.rejected]: () => {
      null;
    },
    [postPriceReportData.pending]: () => {
      null;
    },
    [postPriceReportData.fulfilled]: (state, action) => {
      state.uploadPriceReportDataResponse = action.payload;
    },
    [postPriceReportData.rejected]: () => {
      null;
    },
    [getGptResponse.pending]: () => {
      null;
    },
    [getGptResponse.fulfilled]: (state, action) => {
      state.getGptDlmResposeDataResponse = action.payload;
    },
    [getGptResponse.rejected]: () => {
      null;
    }
  }
});

export const subidaDeDocumentosSelector = (state) =>
  state.subidaDeDocumentosData;

export default subidaDeDocumentosDataSlice.reducer;
