import React, { useState, useRef, useEffect } from 'react';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
  Modal,
  Grid
} from '@mui/material';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
// COMPONENTS
import Button from '../../MTWActions/Button';
import CustomComboBox from '../../MTWDataDisplay/ComboBoxCustom';
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserData,
  userdataSelector
} from 'features/userFeatures/userdata.store';
import {
  getCompanyList,
  companySelector
} from 'features/cadastroFeatures/Company/company.store';
// SERVICES
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import { maskedValue } from 'services/maskedValue';
import { Auth } from 'aws-amplify';
import { Storage } from 'aws-amplify';
// STYLE
import theme from 'theme';

export default function UserMenu({ setLogoFile }) {
  const effectMonitor1 = useRef(false);
  const effectMonitor2 = useRef(false);
  const { companies } = useSelector(companySelector);
  const { userdata } = useSelector(userdataSelector);
  // control
  const [loadAvatarCounter, setLoadAvatarCounter] = useState(0);
  // objects
  const [imageUrl, setImageUrl] = useState(null);
  const [companyOptions, setCompanyOptions] = useState(null);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  // localStorage
  const [localCompany, setLocalCompany] = useState('');
  const [previousCompany, setPreviousCompany] = useState('');
  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const resetLocalStorage = () => {
    window.localStorage.setItem('CURRENT_COMPANY', '');
    window.localStorage.setItem('COMPANY_PICKED', false);
    window.localStorage.setItem('COMPANY_AUTOM_ITER', false);
  };
  /**/

  useEffect(() => {
    if (effectMonitor1.current === false) {
      dispatch(getCompanyList());
      dispatch(getUserData());
      effectMonitor1.current = true;
    }
  }, []);
  /**/

  const companydata = companies?.items;

  const handleSetCompany = (event, newValue) => {
    setCompanyOptions(newValue);
    window.localStorage.setItem('COMPANY_PICKED', true);
  };
  /*companyOptions alterado manualmente*/

  useEffect(() => {
    if (companyOptions) setLocalCompany(companyOptions.IdEmpresa);
  }, [companyOptions]);
  /*companyOptions após alterado manualmente atualiza o localCompany*/

  useEffect(() => {
    const id = window.localStorage.getItem('CURRENT_COMPANY');
    if (id !== null && id !== '' && id !== undefined) {
      setLocalCompany(id);
    }
  }, []);
  /*CURRENT_COMPANY GET e atualização do localCompany*/

  useEffect(() => {
    window.localStorage.setItem('CURRENT_COMPANY', localCompany);
    window.dispatchEvent(new Event('storage'));
  }, [localCompany]);
  /*CURRENT_COMPANY SET com o valor do localCompany*/

  useEffect(() => {
    const picked = window.localStorage.getItem('COMPANY_PICKED');
    if (!picked) {
      window.localStorage.setItem('COMPANY_PICKED', false);
    }
  }, []);
  /*COMPANY_PICKED criado*/

  useEffect(() => {
    const loop = window.localStorage.getItem('COMPANY_AUTOM_ITER');
    if (!loop || loop === undefined || loop === null || loop === '') {
      window.localStorage.setItem('COMPANY_AUTOM_ITER', false);
    }
  }, []);
  /*COMPANY_AUTOM_ITER criado*/

  useEffect(() => {
    let id = window.localStorage.getItem('CURRENT_COMPANY');
    if (id && id !== '' && id !== null && id !== undefined) {
      let previous = companies?.items?.findIndex(
        (row) => row.IdEmpresa === localCompany
      );
      setPreviousCompany(previous);
      //if there is already a company on the localStorage by any means
    }
  }, [companies]);
  /*previousCompany*/

  useEffect(() => {
    //empresas:
    let cia = companies?.items;
    //o valor do id da empresa no browser:
    let myId = window.localStorage.getItem('CURRENT_COMPANY');
    //o booleano para empresa escolhida ou não escolhida manualmente:
    let myPick = window.localStorage.getItem('COMPANY_PICKED');
    //o booleano para quando a empresa não deve mais ser escolhida automaticamente:
    let myIterator = window.localStorage.getItem('COMPANY_AUTOM_ITER');
    let id = () => (myId === '' ? false : true);
    let pick = () => (myPick === 'false' ? false : true);
    let iterator = () => (myIterator === 'false' ? false : true);
    //dataChecker(cia, id, pick, iterator);
    if (id() === false) {
      if (cia && !pick() && !iterator()) {
        //nenhuma empresa foi manualmente selecionada e o iterador é 0
        // console.log('--- Companies.items[0]');
        window.localStorage.setItem('COMPANY_AUTOM_ITER', true);
        setCompanyOptions(companies?.items[0]);
      } else if (cia && !pick() && !localCompany) {
        //nenhuma foi manualmente selecionada e o companyOptions está vazio
        // console.log('--- Sel. por companyOptions vazio');
        setCompanyOptions(companies?.items[0]);
      }
    } else {
      if (localCompany) {
        // console.log('--- Sobrescrita pelo reload');
        setCompanyOptions(companies?.items[previousCompany]);
      }
    }
  }, [companies]);
  /*companyOptions alterado inicialmente em caso de vazio - End of new implementation*/

  function openModal() {
    setModal(true);
  }
  function closeModal() {
    setModal(false);
  }
  /*Modal do Material UI*/

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const signOut = async () => {
    try {
      await Auth.signOut({ global: true });
      document.location.reload(true);
      resetLocalStorage();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const handleChange = async (e) => {
    const file = e.target.files[0];
    try {
      //upload file
      if (file.type !== 'image/jpeg') {
        toast.warning('File not supported. You must use .jpeg!');
      } else {
        await Storage.put('UserIcon.jpeg', file, {
          level: 'private',
          contentType: 'image/jpeg',
          region: 'us-east-1'
        });
      }
      const imgurl = await Storage.get('UserIcon.jpeg', {
        level: 'private',
        region: 'us-east-1'
      });
      setImageUrl(imgurl);
    } catch (err) {
      console.log(err);
    }
  };
  /*Upload profile image function triggered by a button*/

  useEffect(() => {
    if (effectMonitor2.current === false) {
      const handleLoadImage = async () => {
        try {
          const url = await Storage.get('UserIcon.jpeg', {
            level: 'private',
            region: 'us-east-1'
          });
          setImageUrl(url);
          setLoadAvatarCounter(loadAvatarCounter + 1);
        } catch (err) {
          setLoadAvatarCounter(loadAvatarCounter + 1);
          console.log(err);
        }
      };
      handleLoadImage();
      effectMonitor2.current = true;
    }
  }, []);

  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    try {
      //upload file
      if (file.type !== 'image/png' || file.size > 15000) {
        toast.warning(
          'File not supported. You must use .png or reduce the file size'
        );
      } else {
        await Storage.put('CompanyLogo.png', file, {
          level: 'private',
          contentType: 'image/png',
          region: 'us-east-1'
        });
      }
      const url = await Storage.get('CompanyLogo.png', {
        level: 'private',
        region: 'us-east-1'
      });
      setLogoFile(url);
    } catch (err) {
      console.log(err);
    }
  };
  /*Triggered by a button*/

  const uploadLogo = () => {
    document.getElementById('selectLogo').click();
  };

  const uploadImage = () => {
    document.getElementById('selectImage').click();
  };

  let location = useLocation();

  const makebiRegex = new RegExp('^/make-bi');

  return (
    <>
      <Stack direction="row" spacing={2}>
        <div>
          <IconButton
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Avatar src={imageUrl ? imageUrl : userdata?.UserName} />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom'
                }}
              >
                <Paper sx={{ borderRadius: 1, paddingRight: '30px' }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <ListItem alignItems="flex-start">
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '12px'
                          }}
                        >
                          <Avatar
                            src={imageUrl ? imageUrl : userdata?.UserName}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start'
                            }}
                          >
                            <div>
                              <Typography sx={{ fontSize: 14 }}>
                                {userdata.UserName}
                              </Typography>
                            </div>
                            <div>
                              <Typography sx={{ fontSize: 12 }}>
                                {userdata.Email}
                              </Typography>
                            </div>
                          </Box>
                        </Box>
                      </ListItem>

                      <Divider
                        sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
                      />

                      <ListItem alignItems="center">
                        <ListItemText>
                          <Box
                            onClick={uploadImage}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'start',
                              cursor: 'pointer'
                            }}
                          >
                            <PersonIcon />
                            <div style={{ fontSize: 15, marginLeft: '5px' }}>
                              Upload Profile Image
                            </div>
                          </Box>
                          <input
                            type="file"
                            id="selectImage"
                            style={{ fontSize: 10, display: 'none' }}
                            accept="image/jpeg"
                            onChange={(evt) => handleChange(evt)}
                          />
                        </ListItemText>
                      </ListItem>

                      <Divider
                        sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
                      />

                      <ListItem alignItems="center">
                        <ListItemText>
                          <Box
                            onClick={openModal}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'start',
                              cursor: 'pointer'
                            }}
                          >
                            <BusinessIcon />
                            <div style={{ fontSize: 15, marginLeft: '5px' }}>
                              Selecionar CNPJ
                            </div>
                          </Box>
                        </ListItemText>
                      </ListItem>

                      <Divider
                        sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
                      />

                      {location.pathname === '/' ||
                      location.pathname.match(makebiRegex) ? (
                        <ListItem alignItems="center">
                          <ListItemText>
                            <Box
                              onClick={uploadLogo}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                cursor: 'pointer'
                              }}
                            >
                              <CloudUploadIcon />
                              <div style={{ fontSize: 15, marginLeft: '5px' }}>
                                Upload Company Logo
                              </div>
                            </Box>
                            <input
                              type="file"
                              id="selectLogo"
                              style={{ fontSize: 10, display: 'none' }}
                              accept="image/png"
                              onChange={(evt) => handleLogoChange(evt)}
                            />
                          </ListItemText>
                        </ListItem>
                      ) : (
                        ''
                      )}

                      <Divider
                        sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
                      />
                      <MenuItem onClick={handleClose}>
                        <ListItem disablePadding onClick={signOut}>
                          <ListItemIcon>
                            <ExitToAppRoundedIcon />
                          </ListItemIcon>
                          <ListItemText
                            sx={{ fontSize: 14 }}
                            primary="Logout"
                          />
                        </ListItem>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>
      <Modal
        open={modal}
        onClose={closeModal}
        aria-labelledby="Nova Ficha 3"
        aria-describedby="Nova Ficha 3"
      >
        <Paper
          titleBadge={false}
          sx={{
            padding: '30px 36px',
            borderRadius: '6px',
            minWidth: '640px',
            minHeight: '280px'
          }}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          {/*Container:*/}
          <Grid
            container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: '8px 0px 8px 0px'
            }}
          >
            {/*Grid item do título do modal*/}
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '30px 10px 30px 10px'
              }}
            >
              <div style={{ fontSize: 16, fontWeight: 600, color: '#757575' }}>
                Selecione o CNPJ da sua empresa:
              </div>
            </Grid>
            {/*Grid item do ComboBox das Empresas*/}
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px 10px'
              }}
            >
              <CustomComboBox
                sx={{
                  fontSize: '10px',
                  width: '540px',
                  backgroundColor: theme.palette.textlight.main
                }}
                options={companydata}
                value={companyOptions}
                setValue={handleSetCompany}
                optionsLabel={(option) => {
                  return (
                    maskedValue(option.Cnpj) + ' ' + '-' + ' ' + option.Nome
                  );
                }}
                label={`Empresa`}
                defaultValue={companydata && companydata.find((f) => f.Cnpj)}
                isOptionEqualToValue={(option, value) => {
                  return option.Cnpj === value.Cnpj;
                }}
              />
            </Grid>
            {/*Grid item do Business Icon*/}
            {companyOptions ? (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '20px 0px 0px 0px'
                }}
              >
                <div
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '6px'
                  }}
                >
                  <BusinessIcon sx={{ color: '#A646DC' }} />
                </div>
                <div style={{ padding: '0px 0px 0px 10px' }}>
                  {companyOptions?.Cnpj}
                  {` - `}
                  {companyOptions?.Nome}
                </div>
              </Grid>
            ) : null}
            {/*Grid item dos botões*/}
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                columnGap: '15px',
                padding: '20px 0px 10px 0px'
              }}
            >
              <Button
                text="Cancel"
                outline
                style={{ height: '40px' }}
                onClick={closeModal}
              >
                Cancelar
              </Button>
              <Button
                text="Importar"
                type="submit"
                primary
                onClick={closeModal}
              >
                Definir
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
}
