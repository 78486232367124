import {
  MTP_BASE_URL,
  ELEMENTAR_BASE_URL,
  ELEMENTAR_ANALYSIS_BASE_URL
} from 'apiRoutes';
import { http, handleErrors } from '../../services/http-wrapper';
import { Auth } from 'aws-amplify';

//Reports:
export const ApiGetCreateReport = async ({ cruzam, cnpj }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_ANALYSIS_BASE_URL}/cruzamento/upload?cnpj=${cnpj}&cruzamento=${cruzam}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetReportKey = async ({ cruzam, cnpj }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_ANALYSIS_BASE_URL}/cruzamento/get/analisis?cnpj=${cnpj}&cruzamento=${cruzam}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//Insumos
export const ApiGetReportsInsumos = async ({
  cnpj,
  data_inicio,
  data_fim,
  tipo_upload
}) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_ANALYSIS_BASE_URL}/insumos/upload?cnpj=${cnpj}&data_inicio=${data_inicio}&data_fim=${data_fim}&tipo_upload=${tipo_upload}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetInsumosKey = async ({ id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_ANALYSIS_BASE_URL}/insumos/${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//Invoices values:
//https://tqbtej6d7l.execute-api.us-east-1.amazonaws.com/make_view_api/total_invoice_by_id_empresa?id_empresa=e6252c69-46a7-414a-9e9a-fbc68c690178
export const ApiGetMakeViewInvValues = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/make_view_api/total_invoice_by_id_empresa?id_empresa=${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//Invoices quantity:
//https://tqbtej6d7l.execute-api.us-east-1.amazonaws.com/make_view_api/total_invoice_by_id_empresa?id_empresa=e6252c69-46a7-414a-9e9a-fbc68c690178
export const ApiGetMakeViewInvQty = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/make_view_api/qtde_invoice_by_id_empresa?id_empresa=${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//Executar Análises
export const ApiGetMakeBiExecuteAnalysis = async ({
  cnpj,
  data_inicio,
  data_fim
}) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.voidGet(
      `${ELEMENTAR_ANALYSIS_BASE_URL}/procedures/call/analises?cnpj=${cnpj}&data_inicio=${data_inicio}&data_fim=${data_fim}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//Write:
export const ApiSaveAskedTypes = async (values) => {
  let done = 'Tipo de arquivo atualizado com sucesso';
  if (values.inicio && values.fim) {
    try {
      let user = await Auth.currentSession();
      const api = await http.simplePut(
        `${ELEMENTAR_BASE_URL}/arquivouploadempresa/update/${values.id}?qtd=${values.qtd}&obs=${values.obs}&datainicio=${values.inicio}&datafim=${values.fim}`,
        values,
        user.getIdToken().getJwtToken(),
        done
      );
      return api.data;
    } catch (error) {
      console.log(error);
      // handlerErros(error);
      throw error;
    }
  } else if (!values.inicio && !values.fim) {
    try {
      let user = await Auth.currentSession();
      const api = await http.simplePut(
        `${ELEMENTAR_BASE_URL}/arquivouploadempresa/update/${values.id}?qtd=${values.qtd}&obs=${values.obs}`,
        values,
        user.getIdToken().getJwtToken(),
        done
      );
      return api.data;
    } catch (error) {
      console.log(error);
      // handlerErros(error);
      throw error;
    }
  }
};

//Delete:
export const ApiDeleteAskedTypes = async (key) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${MTP_BASE_URL}/stored_files/delete/${key}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};
