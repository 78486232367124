import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ApiGetCompanyList,
  ApiGetNotasByCnpj,
  ApiGetNotasFiltered,
  ApiGetSingleNfe,
  ApiGetNCMDesc,
  ApiGetNCMCNAE,
  ApiGetICMSIncid,
  ApiGetICMSAliq,
  ApiGetDANFESide,
  ApiGetDANFEServ,
  ApiPostNotaManifestacao,
  ApiPostCertificateUpdate,
  ApiPostNotaFiles,
  ApiGetDocData,
  ApiGetDocItens,
  ApiGetZipFiles,
  ApiPostTag,
  ApiGetTags,
  ApiDeleteTag,
  ApiPostTagAviso,
  ApiUpdateTagAviso,
  ApiGetTagAviso,
  ApiDeleteTagAviso,
  ApiGetTagWorkflow,
  ApiPostTagWorkflow,
  ApiDeleteTagWorkflow,
  ApiPostTagNota,
  ApiGetTagValidation,
  ApiPostTagExecucao,
  ApiGetTagExecucoes,
  ApiUpdateTagExecucao,
  ApiDeleteTagExecucao,
  ApiPostXlsInvoice,
  ApiGetInvoiceSystemMessage,
  ApiUpdateInvoiceQtd,
  ApiGetInvoiceErrors,
  ApiPostLoopNcm,
  ApiGetInvoiceEvents,
  ApiGetCrossedNcm,
  ApiNcmSelector,
  ApiValidateInvoiceItems,
  ApiGetReadActions,
  ApiGetReadActionsNota,
  ApiPostReadActions,
  ApiPostReadActionsNota,
  ApiUpdateActionsNota,
  // Pedidos
  ApiGetInvoiceOrderItems,
  ApiPostOrderConfirmation,
  ApiGetOrderByInvId,
  ApiGetInvOrder,
  ApiGetOrderItem,
  ApiGetOrderById,
  ApiGetOrderByCnpj,
  ApiPostInvOrder,
  ApiPostInvOrderItem,
  ApiPostInvOrderByFile,
  ApiUpdateInvOrder,
  ApiUpdateInvOrderItem,
  ApiDeleteInvOrder,
  ApiDeleteInvOrderItem,
  // NFSe
  ApiGetNotasServico,
  ApiPostNfseFiles,
  ApiGetInvCodMunicipio,
  ApiGetSingleNfse,
  // CTE
  ApiGetNotasCte,
  ApiPostNotaCte,
  ApiGetDANFECte,
  ApiGetSingleCte,
  //human loop
  ApiHumanLoopValidateNcm,
  ApiHumanLoopModifyTrainingData
} from './makeForward.api';
import { createAction } from '@reduxjs/toolkit';

export const resetPostNotaIteration = createAction(
  'notaavulsa/resetpostnotaiter'
);

export const recordMakeForwardInvoiceDetails = createAction(
  'notaavulsa/invoicedetailstemp',
  (data) => ({
    payload: data
  })
);

//Tags
export const postTag = createAsyncThunk(
  'api/posttag',
  async ({ tag_name, cnpj, cor, tipo_documento }) => {
    let response = await ApiPostTag({
      tag_name,
      cnpj,
      cor,
      tipo_documento
    });
    return response;
  }
);

export const getTags = createAsyncThunk(
  'api/gettags',
  async ({ id_empresa, tipo_documento }) => {
    let response = await ApiGetTags({ id_empresa, tipo_documento });
    return response;
  }
);

export const deleteTag = createAsyncThunk('api/deletetag', async (Id) => {
  let response = await ApiDeleteTag(Id);
  return response;
});

export const postTagAviso = createAsyncThunk(
  'api/posttagaviso',
  async ({ id_tag, email, whatsapp, desc_aviso }) => {
    let response = await ApiPostTagAviso({
      id_tag,
      email,
      whatsapp,
      desc_aviso
    });
    return response;
  }
);

export const updateTagAviso = createAsyncThunk(
  'api/updatetagaviso',
  async ({ id_tag, email, whatsapp, desc_aviso }) => {
    let response = await ApiUpdateTagAviso({
      id_tag,
      email,
      whatsapp,
      desc_aviso
    });
    return response;
  }
);

export const getTagAviso = createAsyncThunk(
  'api/gettagaviso',
  async (id_tag) => {
    let response = await ApiGetTagAviso(id_tag);
    return response;
  }
);

export const deleteTagAviso = createAsyncThunk(
  'api/deletetagaviso',
  async (id_aviso) => {
    let response = await ApiDeleteTagAviso(id_aviso);
    return response;
  }
);

export const getTagWorkflow = createAsyncThunk(
  'api/gettagworkflow',
  async ({ id_empresa, page, tipo_documento }) => {
    let response = await ApiGetTagWorkflow({
      id_empresa,
      page,
      tipo_documento
    });
    return response;
  }
);

export const postTagWorkflow = createAsyncThunk(
  'api/posttagworkflow',
  async ({
    tipo_nota,
    id_tag_orig,
    id_tag_dest,
    cnpj,
    tipo_validacao,
    tipo_documento
  }) => {
    let response = await ApiPostTagWorkflow({
      tipo_nota,
      id_tag_orig,
      id_tag_dest,
      cnpj,
      tipo_validacao,
      tipo_documento
    });
    return response;
  }
);

export const deleteTagWorkflow = createAsyncThunk(
  'api/deletetagworkflow',
  async (tag_workflow) => {
    let response = await ApiDeleteTagWorkflow(tag_workflow);
    return response;
  }
);

export const postTagNota = createAsyncThunk(
  'api/posttagnota',
  async ({ id_notas, id_tag, tipo_documento }) => {
    let response = await ApiPostTagNota({
      id_notas,
      id_tag,
      tipo_documento
    });
    return response;
  }
);

export const getTagValidation = createAsyncThunk(
  'api/tagvalidation',
  async ({ id_notas, id_tag, status, tipo_documento }) => {
    let response = await ApiGetTagValidation({
      id_notas,
      id_tag,
      status,
      tipo_documento
    });
    return response;
  }
);

export const postTagExecucao = createAsyncThunk(
  'api/posttagexecucao',
  async ({ id_tag, execucao, lambda, descricao_lambda }) => {
    let response = await ApiPostTagExecucao({
      id_tag,
      execucao,
      lambda,
      descricao_lambda
    });
    return response;
  }
);

export const getTagExecucoes = createAsyncThunk(
  'api/tagexecucoes',
  async ({ id_tag, page, per_page }) => {
    let response = await ApiGetTagExecucoes({ id_tag, page, per_page });
    return response;
  }
);

export const updateTagExecucao = createAsyncThunk(
  'api/updatetagexecucao',
  async ({ integration, descricao_Lambda, execucao, id_tag, Lambda }) => {
    let response = await ApiUpdateTagExecucao({
      integration,
      descricao_Lambda,
      execucao,
      id_tag,
      Lambda
    });
    return response;
  }
);

export const deleteTagExecucao = createAsyncThunk(
  'api/deletetagexecucao',
  async (tag_execucao) => {
    let response = await ApiDeleteTagExecucao(tag_execucao);
    return response;
  }
);

// HUMAN LOOP
export const postLoopNcm = createAsyncThunk(
  'api/postloopncm',
  async ({ ncm, description }) => {
    let response = await ApiPostLoopNcm({ ncm, description });
    return response;
  }
);

export const postNcmSelector = createAsyncThunk(
  'api/ncmselectorloop',
  async ({ ncm, ufs }) => {
    let response = await ApiNcmSelector({ ncm, ufs });
    return response;
  }
);

export const humanLoopValidateNcm = createAsyncThunk(
  'api/humanloopncm',
  async ({ ncm, descricao }) => {
    let response = await ApiHumanLoopValidateNcm({ ncm, descricao });
    return response;
  }
);

export const HumanLoopModifyTrainingData = createAsyncThunk(
  'api/humanloopncm',
  async ({ status, ncm, descricao, modify }) => {
    let response = await ApiHumanLoopModifyTrainingData({
      status,
      ncm,
      descricao,
      modify
    });
    return response;
  }
);

//MAKE FORWARD HOME
export const getZipFiles = createAsyncThunk(
  'api/nazipfiles',
  async ({ cnpj, data_inicio, data_fim, tipo_nota }) => {
    let response = await ApiGetZipFiles({
      cnpj,
      data_inicio,
      data_fim,
      tipo_nota
    });
    return response;
  }
);

export const getNotas = createAsyncThunk(
  'api/notas',
  async ({ cnpj, page, size }) => {
    let response = await ApiGetNotasByCnpj({ cnpj, page, size });
    return response;
  }
);

export const getNotasFiltered = createAsyncThunk(
  'api/notasfiltered',
  async (notaAvulsaFilters) => {
    let response = await ApiGetNotasFiltered(notaAvulsaFilters);
    return response;
  }
);

export const getSingleNfe = createAsyncThunk('api/notaid', async (uuid) => {
  let response = await ApiGetSingleNfe(uuid);
  return response;
});

export const getInvoiceSystemMessage = createAsyncThunk(
  'api/invoicemessage',
  async (empresa_id) => {
    let response = await ApiGetInvoiceSystemMessage(empresa_id);
    return response;
  }
);

export const updateInvoiceQtd = createAsyncThunk(
  'api/updateinvoiceqtd',
  async (body) => {
    let response = await ApiUpdateInvoiceQtd(body);
    return response;
  }
);

export const validateInvoiceItems = createAsyncThunk(
  'api/validateinvoiceitems',
  async (body) => {
    let response = await ApiValidateInvoiceItems(body);
    return response;
  }
);

//Make Forward NFS-e
export const getNotasServico = createAsyncThunk(
  'api/notasservico',
  async (notasServico) => {
    let response = await ApiGetNotasServico(notasServico);
    return response;
  }
);

export const postNotaNfseFiles = createAsyncThunk(
  'api/postinvoicenfse',
  async ({ arquivoXml, codMunicipio, idEmpresa, tipoNota }) => {
    let response = await ApiPostNfseFiles({
      arquivoXml,
      codMunicipio,
      idEmpresa,
      tipoNota
    });
    return response;
  }
);

export const getInvoiceCodMunicipio = createAsyncThunk(
  'api/getinvoicecodmunicipio',
  async (uuid) => {
    let response = await ApiGetInvCodMunicipio(uuid);
    return response;
  }
);

//Make Forward Cte
export const getNotasCte = createAsyncThunk(
  'api/notascte',
  async (notasCteFilters) => {
    let response = await ApiGetNotasCte(notasCteFilters);
    return response;
  }
);

export const postNotaCte = createAsyncThunk(
  'api/notacteupload',
  async ({ arquivoXml, idEmpresa, tipoNota }) => {
    let response = await ApiPostNotaCte({
      arquivoXml,
      idEmpresa,
      tipoNota
    });
    return response;
  }
);

//MAKE FORWARD INVOICE ANALYSIS DEPRECATED
export const getNCMDesc = createAsyncThunk('api/ncmdesc', async (id) => {
  let response = await ApiGetNCMDesc(id);
  return response;
});

export const getNCMCNAE = createAsyncThunk('api/ncmcnae', async (id) => {
  let response = await ApiGetNCMCNAE(id);
  return response;
});

export const getICMSIncid = createAsyncThunk(
  'api/icmsincidencia',
  async (id) => {
    let response = await ApiGetICMSIncid(id);
    return response;
  }
);

export const getICMSAliq = createAsyncThunk(
  'api/icmsaliquota',
  async ({ id, empresa_id }) => {
    let response = await ApiGetICMSAliq({ id, empresa_id });
    return response;
  }
);

// MAKE FORWARD INVOICE DETAILS
export const getDocData = createAsyncThunk(
  'api/docdata',
  async ({ id, type }) => {
    let response = await ApiGetDocData({ id, type });
    return response;
  }
);

export const getInvoiceEvents = createAsyncThunk(
  'api/mfinvoiceevents',
  async ({ id, page, perPage }) => {
    let response = await ApiGetInvoiceEvents({ id, page, perPage });
    return response;
  }
);

export const getDocItens = createAsyncThunk('api/docitens', async (uuid) => {
  let response = await ApiGetDocItens(uuid);
  return response;
});

// ORDER
export const getInvoiceOrderItems = createAsyncThunk(
  'api/getinvoiceorderitems',
  async (uuid) => {
    let response = await ApiGetInvoiceOrderItems(uuid);
    return response;
  }
);

export const getOrderByInvId = createAsyncThunk(
  'api/getorderbyinvid',
  async ({ nota_id, page, per_page }) => {
    let response = await ApiGetOrderByInvId({ nota_id, page, per_page });
    return response;
  }
);

export const getInvOrder = createAsyncThunk(
  'api/getinvorder',
  async ({ page, per_page }) => {
    let response = await ApiGetInvOrder({ page, per_page });
    return response;
  }
);

export const getOrderItem = createAsyncThunk(
  'api/getorderitem',
  async ({ page, per_page }) => {
    let response = await ApiGetOrderItem({ page, per_page });
    return response;
  }
);

export const getOrderById = createAsyncThunk(
  'api/getorderbyid',
  async ({ id, page, per_page }) => {
    let response = await ApiGetOrderById({ id, page, per_page });
    return response;
  }
);

export const getOrderByCnpj = createAsyncThunk(
  'api/getorderbycnpj',
  async ({ cnpj }) => {
    let response = await ApiGetOrderByCnpj({ cnpj });
    return response;
  }
);

export const postInvoiceOrderConfirmation = createAsyncThunk(
  'api/postorderconfirmation',
  ({ id_dados_itens, id_item_ped, quantidade }) => {
    let response = ApiPostOrderConfirmation({
      id_dados_itens,
      id_item_ped,
      quantidade
    });
    return response;
  }
);

export const postInvOrder = createAsyncThunk(
  'api/postinvorder',
  ({ cnpj_emit, cnpj_dest, data_emissao, tipo_fret, num_pedido }) => {
    let response = ApiPostInvOrder({
      cnpj_emit,
      cnpj_dest,
      data_emissao,
      tipo_fret,
      num_pedido
    });
    return response;
  }
);

export const postInvOrderItem = createAsyncThunk(
  'api/postinvorderitem',
  ({
    id_pedido,
    num_item_pedido,
    cod_prod_interno,
    unid_medida_interna,
    qtd_pedido,
    vl_unitario,
    cod_prod_fornecedor,
    unid_medida_fornecedor,
    taxa_conversao,
    tipo_conversao
  }) => {
    let response = ApiPostInvOrderItem({
      id_pedido,
      num_item_pedido,
      cod_prod_interno,
      unid_medida_interna,
      qtd_pedido,
      vl_unitario,
      cod_prod_fornecedor,
      unid_medida_fornecedor,
      taxa_conversao,
      tipo_conversao
    });
    return response;
  }
);

export const postInvOrderByFile = createAsyncThunk(
  'api/postinvorderbyfile',
  ({ xml }) => {
    let response = ApiPostInvOrderByFile({
      xml
    });
    return response;
  }
);

export const updateInvOrder = createAsyncThunk(
  'api/updateinvorder',
  async ({ id, cnpj_emit, cnpj_dest, data_emissao, tipo_fret, num_pedido }) => {
    let response = await ApiUpdateInvOrder({
      id,
      cnpj_emit,
      cnpj_dest,
      data_emissao,
      tipo_fret,
      num_pedido
    });
    return response;
  }
);

export const updateInvOrderItem = createAsyncThunk(
  'api/updateinvorderitem',
  async ({
    id,
    num_item_pedido,
    cod_prod_interno,
    unid_medida_interna,
    qtd_pedido,
    vl_unitario,
    cod_prod_fornecedor,
    unid_medida_fornecedor,
    taxa_conversao,
    tipo_conversao
  }) => {
    let response = await ApiUpdateInvOrderItem({
      id,
      num_item_pedido,
      cod_prod_interno,
      unid_medida_interna,
      qtd_pedido,
      vl_unitario,
      cod_prod_fornecedor,
      unid_medida_fornecedor,
      taxa_conversao,
      tipo_conversao
    });
    return response;
  }
);

export const deleteInvOrder = createAsyncThunk(
  'api/deleteinvorder',
  async (id) => {
    let response = await ApiDeleteInvOrder(id);
    return response;
  }
);

export const deleteInvOrderItem = createAsyncThunk(
  'api/deleteinvorderitem',
  async (id) => {
    let response = await ApiDeleteInvOrderItem(id);
    return response;
  }
);

// MAKE FORWARD INVOICE ERRORS
export const getInvoiceErrors = createAsyncThunk(
  'api/invoiceerrors',
  async ({ id, page, per_page }) => {
    let response = await ApiGetInvoiceErrors({ id, page, per_page });
    return response;
  }
);

export const getInvoiceCrossedNcm = createAsyncThunk(
  'api/invoicecrossedncm',
  async ({ ncm, ncm_pred }) => {
    let response = await ApiGetCrossedNcm({ ncm, ncm_pred });
    return response;
  }
);

// MISC
export const getNotaCompanyList = createAsyncThunk('api/empresa', async () => {
  let response = await ApiGetCompanyList();
  return response;
});

export const postCertificate = createAsyncThunk(
  'api/notacertificate',
  async ({ uuid, certificado, cpfCnpj, razaoSocial, senhaCertificado, uf }) => {
    let response = await ApiPostCertificateUpdate({
      uuid,
      certificado,
      cpfCnpj,
      razaoSocial,
      senhaCertificado,
      uf
    });
    return response;
  }
);

export const postXlsInvoice = createAsyncThunk('api/notaxls', (xlsInvoice) => {
  let response = ApiPostXlsInvoice(xlsInvoice);
  return response;
});

export const postNotaFiles = createAsyncThunk(
  'api/notafilesxml',
  async ({ arquivoXml, idEmpresa, tipoNota, nome }) => {
    let response = await ApiPostNotaFiles({
      arquivoXml,
      idEmpresa,
      tipoNota,
      nome
    });
    return response;
  }
);

// OTHERS
export const getDANFE = createAsyncThunk('api/notadanfe', async (uuid) => {
  let response = await ApiGetDANFESide(uuid);
  return response;
});

export const getDANFEServ = createAsyncThunk(
  'api/notadanfeserv',
  async (uuid) => {
    let response = await ApiGetDANFEServ(uuid);
    return response;
  }
);

export const getDANFECte = createAsyncThunk(
  'api/cteimpressao',
  async (uuid) => {
    let response = await ApiGetDANFECte(uuid);
    return response;
  }
);

export const getSingleCte = createAsyncThunk('api/singlecte', async (uuid) => {
  let response = await ApiGetSingleCte(uuid);
  return response;
});

export const getSingleNfse = createAsyncThunk(
  'api/singlenfse',
  async (uuid) => {
    let response = await ApiGetSingleNfse(uuid);
    return response;
  }
);

export const postNota = createAsyncThunk(
  'api/notamanifestacao',
  async ({ uuid, tipoManifestacao, motivo }) => {
    let response = await ApiPostNotaManifestacao({
      uuid,
      tipoManifestacao,
      motivo
    });
    return response;
  }
);

export const getReadActions = createAsyncThunk(
  'api/getreadactions',
  async (empresa_id) => {
    let response = await ApiGetReadActions(empresa_id);
    return response;
  }
);

export const getReadActionsNota = createAsyncThunk(
  'api/getreadactionsnota',
  async (uuid) => {
    let response = await ApiGetReadActionsNota(uuid);
    return response;
  }
);

export const postReadActions = createAsyncThunk(
  'api/postreadactions',
  async ({ email, nome, empresa }) => {
    let response = await ApiPostReadActions({
      email,
      nome,
      empresa
    });
    return response;
  }
);

export const postReadActionsNota = createAsyncThunk(
  'api/postreadactionsnota',
  async ({ data_inclusao, descricao, email, id_nota, id_acao }) => {
    let response = await ApiPostReadActionsNota({
      data_inclusao,
      descricao,
      email,
      id_nota,
      id_acao
    });
    return response;
  }
);

export const updateActionsNota = createAsyncThunk(
  'api/updateactionsnota',
  async ({ id, data_fechamento }) => {
    let response = await ApiUpdateActionsNota({
      id,
      data_fechamento
    });
    return response;
  }
);

export const notaAvulsaDataSlice = createSlice({
  name: 'notaavulsa',
  initialState: {
    //Make Forward CTE:
    isLoadingNotasCte: false,
    notasCte: [],
    isPostNotaCte: false,
    successPostNotaCte: [],
    successPostNotaCteQtd: 0,
    failPostNotaCte: [],
    failPostNotaQtdCte: 0,
    notaCteBody: {},
    //Make Forward Standard:
    isLoadingCompanyList: false,
    companyList: [],
    isLoadingNumericCompanyId: false,
    numericCompanyId: {},
    isLoadingNotas: false,
    notas: [],
    isLoadingNotasFiltered: false,
    notasFiltered: [],
    isLoadingNotasServico: false,
    notasServico: [],
    isLoadingNotaId: false,
    notaId: [],
    isLoadingInvSysMsg: false,
    invoiceSystemMessage: [],
    isLoadingInvCodMunicipio: false,
    invCodMunicipio: [],
    //Make Forward Actions
    isLoadingZipFiles: false,
    zipFiles: [],
    isPostNota: false,
    notaBody: {},
    isUpdatingInvoiceQtd: false,
    updateInvoiceQtd: [],
    isValidatingInvoiceItems: false,
    validateInvoiceItems: [],
    isLoadingReadActions: false,
    getReadActions: [],
    isLoadingReadActionsNota: false,
    getReadActionsNota: [],
    isPostReadActions: false,
    postReadActions: {},
    isPostReadActionsNota: false,
    postReadActionsNota: {},
    isUpdatingActionsNota: false,
    updateActionsNota: [],
    //MAKE FORWARD HUMAN LOOP
    isPostLoopNcm: false,
    loopNcmBody: {},
    isPostNcmSelector: false,
    ncmSelector: {},
    //MAKE FORWARD CERTIFICATE
    isPostCertificate: false,
    certificateBody: {},
    isPostNotaFiles: false,
    successPostNota: [],
    successPostNotaQtd: 0,
    failPostNota: [],
    failPostNotaQtd: 0,
    notaFilesBody: {},
    // MAKE FORWARD DETAILS
    crossPageCnpj: {},
    makeForwardInvoiceDetails: [],
    isLoadingNotaDocData: false,
    isNotaDocDataDone: false,
    notaDocData: {},
    isLoadingNotaDocItens: false,
    notaDocItens: [],
    isLoadingInvoiceEvents: false,
    invoiceEvents: {},
    //ORDER
    isInvoiceOrderItems: false,
    invoiceOrderItems: [],
    isLoadingOrderByInvId: false,
    orderByInvId: {},
    isLoadingInvOrder: false,
    invOrder: {},
    isLoadingOrderItem: false,
    orderItem: {},
    isLoadingOrderById: false,
    orderById: {},
    isLoadingOrderByCnpj: false,
    orderByCnpj: [],
    isPostInvoiceOrderConfirmation: false,
    isPostInvOrder: false,
    postInvOrder: {},
    isPostInvOrderItem: false,
    postInvOrderItem: {},
    isPostInvOrderByFile: false,
    postInvOrderByFile: {},
    isUpdatingInvOrder: false,
    updateInvOrder: {},
    isUpdatingInvOrderItem: false,
    updateInvOrderItem: {},
    isDeletingInvOrder: false,
    deleteInvOrder: {},
    isDeletingInvOrderItem: false,
    deleteInvOrderItem: {},
    //MAKE FORWARD ANALYSIS DEPRECATED
    isLoadingNCMDesc: false,
    notaNCMDesc: [],
    isLoadingNCMCNAE: false,
    notaNCMCNAE: [],
    isLoadingICMSIncid: false,
    notaICMSIncid: [],
    isLoadingICMSAliquota: false,
    notaICMSAliquota: [],
    isLoadingDANFE: false,
    notaDANFE: [],
    isLoadingDANFEServ: false,
    notaDANFEServ: [],
    isLoadingDANFECte: false,
    notaDANFECte: [],
    isLoadingSingleCte: false,
    singleCte: [],
    isLoadingSingleNfse: false,
    singleNfse: [],
    //MAKE FORWARD INVOICE ERRORS
    isLoadingInvoiceErrors: false,
    invoiceErrors: {},
    isLoadingInvoiceNcmCrossed: false,
    invoiceNcmCrossed: {},
    //Make Forward Tags:
    isPostTag: false,
    postTag: [],
    isLoadingTags: false,
    tags: [],
    isDeletingTag: false,
    deleteTag: {},
    isPostTagAviso: false,
    postTagAviso: [],
    isUpdatingTagAviso: false,
    updateTagAviso: [],
    isLoadingTagAviso: false,
    tagAviso: [],
    isDeletingTagAviso: false,
    deleteTagAviso: [],
    isLoadingTagWorkflow: false,
    tagWorkflow: [],
    isPostTagWorkflow: false,
    postTagWorkflow: [],
    isDeletingTagWorkflow: false,
    deleteTagWorkflowMessage: false,
    deleteTagWorkflow: [],
    isPostTagNota: false,
    postTagNota: [],
    isGetTagValidation: false,
    tagValidation: [],
    isPostTagExecucao: false,
    postTagExecucao: [],
    isLoadingTagExecucoes: false,
    tagExecucoes: [],
    isUpdatingTagExecucao: false,
    updateTagExecucao: [],
    isDeletingTagExecucao: false,
    deleteTagExecucao: [],
    isPostXlsInvoice: false,
    xlsInvoice: [],
    // NCM
    ncmValidateResponse: [],
    ModifyTrainingDataResponse: []
  },
  reducers: {},
  extraReducers: {
    [recordMakeForwardInvoiceDetails]: (state, action) => {
      state.makeForwardInvoiceDetails = action.payload;
    },
    [recordMakeForwardInvoiceDetails]: (state, action) => {
      state.makeForwardInvoiceDetails = action.payload;
    },
    [resetPostNotaIteration]: (state) => {
      state.failPostNota = [];
      state.failPostNotaQtd = 0;
      state.successPostNota = [];
      state.successPostNotaQtd = 0;
    },
    [getZipFiles.pending]: (state) => {
      state.isLoadingZipFiles = 'loading';
    },
    [getZipFiles.fulfilled]: (state, action) => {
      state.isLoadingZipFiles = 'success';
      state.zipFiles = action.payload;
    },
    [getZipFiles.rejected]: (state) => {
      state.isLoadingZipFiles = 'failed';
    },
    [getNotaCompanyList.pending]: (state) => {
      state.isLoadingCompanyList = 'loading';
    },
    [getNotaCompanyList.fulfilled]: (state, action) => {
      state.isLoadingCompanyList = 'success';
      state.companyList = action.payload;
    },
    [getNotaCompanyList.rejected]: (state) => {
      state.isLoadingCompanyList = 'failed';
    },
    [getNotas.pending]: (state) => {
      state.isLoadingNotas = 'loading';
    },
    [getNotas.fulfilled]: (state, action) => {
      state.isLoadingNotas = 'success';
      state.notas = action.payload;
    },
    [getNotas.rejected]: (state) => {
      state.isLoadingNotas = 'failed';
    },
    [getSingleNfe.pending]: (state) => {
      state.isLoadingNotaId = 'loading';
    },
    [getSingleNfe.fulfilled]: (state, action) => {
      state.isLoadingNotaId = 'success';
      state.notaId = action.payload;
    },
    [getSingleNfe.rejected]: (state) => {
      state.isLoadingNotaId = 'failed';
    },
    [getNotasFiltered.pending]: (state) => {
      state.isLoadingNotasFiltered = 'loading';
    },
    [getNotasFiltered.fulfilled]: (state, action) => {
      state.isLoadingNotasFiltered = 'success';
      state.notasFiltered = action.payload;
    },
    [getNotasFiltered.rejected]: (state) => {
      state.isLoadingNotasFiltered = 'failed';
    },
    [getNotasServico.pending]: (state) => {
      state.isLoadingNotasServico = 'loading';
    },
    [getNotasServico.fulfilled]: (state, action) => {
      state.isLoadingNotasServico = 'success';
      state.notasServico = action.payload;
    },
    [getNotasServico.rejected]: (state) => {
      state.isLoadingNotasServico = 'failed';
    },
    [getNotasCte.pending]: (state) => {
      state.isLoadingNotasCte = 'loading';
    },
    [getNotasCte.fulfilled]: (state, action) => {
      state.isLoadingNotasCte = 'success';
      state.notasCte = action.payload;
    },
    [getNotasCte.rejected]: (state) => {
      state.isLoadingNotasCte = 'failed';
    },
    [postNota.pending]: (state) => {
      state.isPostNota = 'loading';
    },
    [postNota.fulfilled]: (state, action) => {
      state.isPostNota = 'success';
      state.notaBody = action.payload;
    },
    [postNota.rejected]: (state) => {
      state.isPostNota = 'failed';
    },
    [updateInvoiceQtd.pending]: (state) => {
      state.isUpdatingInvoiceQtd = 'loading';
    },
    [updateInvoiceQtd.fulfilled]: (state, action) => {
      state.isUpdatingInvoiceQtd = 'success';
      state.updateInvoiceQtd = action.payload;
    },
    [updateInvoiceQtd.rejected]: (state) => {
      state.isUpdatingInvoiceQtd = 'failed';
    },
    //...
    [validateInvoiceItems.pending]: (state) => {
      state.isValidatingInvoiceItems = 'loading';
    },
    [validateInvoiceItems.fulfilled]: (state, action) => {
      state.isValidatingInvoiceItems = 'success';
      state.validateInvoiceItems = action.payload;
    },
    [validateInvoiceItems.rejected]: (state) => {
      state.isValidatingInvoiceItems = 'failed';
    },
    // NFSe
    [getInvoiceSystemMessage.pending]: (state) => {
      state.isLoadingInvSysMsg = 'loading';
    },
    [getInvoiceSystemMessage.fulfilled]: (state, action) => {
      state.isLoadingInvSysMsg = 'success';
      state.invoiceSystemMessage = action.payload;
    },
    [getInvoiceSystemMessage.rejected]: (state) => {
      state.isLoadingInvSysMsg = 'failed';
    },
    [getInvoiceCodMunicipio.pending]: (state) => {
      state.isLoadingInvCodMunicipio = 'loading';
    },
    [getInvoiceCodMunicipio.fulfilled]: (state, action) => {
      state.isLoadingInvCodMunicipio = 'success';
      state.invCodMunicipio = action.payload;
    },
    [getInvoiceCodMunicipio.rejected]: (state) => {
      state.isLoadingInvCodMunicipio = 'failed';
    },
    [getDANFE.pending]: (state) => {
      state.isLoadingDANFE = 'loading';
    },
    [getDANFE.fulfilled]: (state, action) => {
      state.isLoadingDANFE = 'success';
      state.notaDANFE = action.payload;
    },
    [getDANFE.rejected]: (state) => {
      state.isLoadingDANFE = 'failed';
    },
    [getDANFEServ.pending]: (state) => {
      state.isLoadingDANFEServ = 'loading';
    },
    [getDANFEServ.fulfilled]: (state, action) => {
      state.isLoadingDANFEServ = 'success';
      state.notaDANFEServ = action.payload;
    },
    [getDANFEServ.rejected]: (state) => {
      state.isLoadingDANFEServ = 'failed';
    },
    //...
    [getDANFECte.pending]: (state) => {
      state.isLoadingDANFECte = 'loading';
    },
    [getDANFECte.fulfilled]: (state, action) => {
      state.isLoadingDANFECte = 'success';
      state.notaDANFECte = action.payload;
    },
    [getDANFECte.rejected]: (state) => {
      state.isLoadingDANFECte = 'failed';
    },
    //...
    [getSingleCte.pending]: (state) => {
      state.isLoadingSingleCte = 'loading';
    },
    [getSingleCte.fulfilled]: (state, action) => {
      state.isLoadingSingleCte = 'success';
      state.singleCte = action.payload;
    },
    [getSingleCte.rejected]: (state) => {
      state.isLoadingSingleCte = 'failed';
    },
    //...
    [getSingleNfse.pending]: (state) => {
      state.isLoadingSingleNfse = 'loading';
    },
    [getSingleNfse.fulfilled]: (state, action) => {
      state.isLoadingSingleNfse = 'success';
      state.singleNfse = action.payload;
    },
    [getSingleNfse.rejected]: (state) => {
      state.isLoadingSingleNfse = 'failed';
    },
    //...
    [getNCMDesc.pending]: (state) => {
      state.isLoadingNCMDesc = 'loading';
    },
    [getNCMDesc.fulfilled]: (state, action) => {
      state.isLoadingNCMDesc = 'success';
      state.notaNCMDesc = action.payload;
    },
    [getNCMDesc.rejected]: (state) => {
      state.isLoadingNCMDesc = 'failed';
    },
    [getNCMCNAE.pending]: (state) => {
      state.isLoadingNCMCNAE = 'loading';
    },
    [getNCMCNAE.fulfilled]: (state, action) => {
      state.isLoadingNCMCNAE = 'success';
      state.notaNCMCNAE = action.payload;
    },
    [getNCMCNAE.rejected]: (state) => {
      state.isLoadingNCMCNAE = 'failed';
    },
    [getICMSIncid.pending]: (state) => {
      state.isLoadingICMSIncid = 'loading';
    },
    [getICMSIncid.fulfilled]: (state, action) => {
      state.isLoadingICMSIncid = 'success';
      state.notaICMSIncid = action.payload;
    },
    [getICMSIncid.rejected]: (state) => {
      state.isLoadingICMSIncid = 'failed';
    },
    [getICMSAliq.pending]: (state) => {
      state.isLoadingICMSAliquota = 'loading';
    },
    [getICMSAliq.fulfilled]: (state, action) => {
      state.isLoadingICMSAliquota = 'success';
      state.notaICMSAliquota = action.payload;
    },
    [getICMSAliq.rejected]: (state) => {
      state.isLoadingICMSAliquota = 'failed';
    },
    [postLoopNcm.pending]: (state) => {
      state.isPostLoopNcm = 'loading';
    },
    [postLoopNcm.fulfilled]: (state, action) => {
      state.isPostLoopNcm = 'success';
      state.loopNcmBody = action.payload;
    },
    [postLoopNcm.rejected]: (state) => {
      state.isPostLoopNcm = 'failed';
    },
    [postNcmSelector.pending]: (state) => {
      state.isPostNcmSelector = 'loading';
    },
    [postNcmSelector.fulfilled]: (state, action) => {
      state.isPostNcmSelector = 'success';
      state.ncmSelector = action.payload;
    },
    [postNcmSelector.rejected]: (state) => {
      state.isPostNcmSelector = 'failed';
    },
    [postCertificate.pending]: (state) => {
      state.isPostCertificate = 'loading';
    },
    [postCertificate.fulfilled]: (state, action) => {
      state.isPostCertificate = 'success';
      state.certificateBody = action.payload;
    },
    [postCertificate.rejected]: (state) => {
      state.isPostCertificate = 'failed';
    },
    [postNotaFiles.pending]: (state) => {
      state.isPostNotaFiles = 'loading';
    },
    [postNotaFiles.fulfilled]: (state, action) => {
      state.isPostNotaFiles = 'success';
      state.notaFilesBody = action.payload;
      state.successPostNota.push({
        arq: action.meta.arg.nome,
        resul: action.type
      });
      state.successPostNotaQtd = state.successPostNotaQtd + 1;
    },
    [postNotaFiles.rejected]: (state, action) => {
      state.isPostNotaFiles = 'failed';
      state.failPostNota.push({
        arq: action.meta.arg.nome,
        resul: action.type
      });
      state.failPostNotaQtd = state.failPostNotaQtd + 1;
    },
    [postXlsInvoice.pending]: (state) => {
      state.isPostXlsInvoice = 'loading';
    },
    [postXlsInvoice.fulfilled]: (state, action) => {
      state.isPostXlsInvoice = 'success';
      state.xlsInvoice = action.payload;
    },
    [postXlsInvoice.rejected]: (state) => {
      state.isPostXlsInvoice = 'failed';
    },
    [getDocData.pending]: (state) => {
      state.isLoadingNotaDocData = 'loading';
      state.isNotaDocDataDone = false;
    },
    [getDocData.fulfilled]: (state, action) => {
      state.isLoadingNotaDocData = 'success';
      state.notaDocData = action.payload;
      state.isNotaDocDataDone = true;
    },
    [getDocData.rejected]: (state) => {
      state.isLoadingNotaDocData = 'failed';
      state.isNotaDocDataDone = true;
    },
    [getInvoiceEvents.pending]: (state) => {
      state.isLoadingInvoiceEvents = 'loading';
    },
    [getInvoiceEvents.fulfilled]: (state, action) => {
      state.isLoadingInvoiceEvents = 'success';
      state.invoiceEvents = action.payload;
    },
    [getInvoiceEvents.rejected]: (state) => {
      state.isLoadingInvoiceEvents = 'failed';
    },
    [getDocItens.pending]: (state) => {
      state.isLoadingNotaDocItens = 'loading';
    },
    [getDocItens.fulfilled]: (state, action) => {
      state.isLoadingNotaDocItens = 'success';
      state.notaDocItens = action.payload;
    },
    [getDocItens.rejected]: (state) => {
      state.isLoadingNotaDocItens = 'failed';
    },
    [getInvoiceErrors.pending]: (state) => {
      state.isLoadingInvoiceErrors = 'loading';
    },
    [getInvoiceErrors.fulfilled]: (state, action) => {
      state.isLoadingInvoiceErrors = 'success';
      state.invoiceErrors = action.payload;
    },
    [getInvoiceCrossedNcm.pending]: (state) => {
      state.isLoadingInvoiceNcmCrossed = 'loading';
    },
    [getInvoiceCrossedNcm.fulfilled]: (state, action) => {
      state.isLoadingInvoiceNcmCrossed = 'success';
      state.invoiceNcmCrossed = action.payload;
    },
    [getInvoiceCrossedNcm.rejected]: (state) => {
      state.isLoadingInvoiceNcmCrossed = 'failed';
    },
    [postTag.pending]: (state) => {
      state.isPostTag = 'loading';
    },
    [postTag.fulfilled]: (state, action) => {
      state.isPostTag = 'success';
      state.postTag = action.payload;
    },
    [postTag.rejected]: (state) => {
      state.isPostTag = 'failed';
    },
    [getTags.pending]: (state) => {
      state.isLoadingTags = 'loading';
    },
    [getTags.fulfilled]: (state, action) => {
      state.isLoadingTags = 'success';
      state.tags = action.payload;
    },
    [getTags.rejected]: (state) => {
      state.isLoadingTags = 'failed';
      state.tags = [];
    },
    [deleteTag.pending]: (state) => {
      state.isDeletingTag = 'loading';
    },
    [deleteTag.fulfilled]: (state, action) => {
      state.isDeletingTag = 'success';
      state.deleteTag = action.payload;
    },
    [deleteTag.rejected]: (state) => {
      state.isDeletingTag = 'failed';
    },
    [postTagAviso.pending]: (state) => {
      state.isPostTagAviso = 'loading';
    },
    [postTagAviso.fulfilled]: (state, action) => {
      state.isPostTagAviso = 'success';
      state.postTagAviso = action.payload;
    },
    [postTagAviso.rejected]: (state) => {
      state.isPostTagAviso = 'failed';
    },
    [updateTagAviso.pending]: (state) => {
      state.isUpdatingTagAviso = 'loading';
    },
    [updateTagAviso.fulfilled]: (state, action) => {
      state.isUpdatingTagAviso = 'success';
      state.updateTagAviso = action.payload;
    },
    [updateTagAviso.rejected]: (state) => {
      state.isUpdatingTagAviso = 'failed';
    },
    [getTagAviso.pending]: (state) => {
      state.isLoadingTagAviso = 'loading';
    },
    [getTagAviso.fulfilled]: (state, action) => {
      state.isLoadingTagAviso = 'success';
      state.tagAviso = action.payload;
    },
    [getTagAviso.rejected]: (state) => {
      state.isLoadingTagAviso = 'failed';
    },
    [deleteTagAviso.pending]: (state) => {
      state.isDeletingTagAviso = 'loading';
    },
    [deleteTagAviso.fulfilled]: (state, action) => {
      state.isDeletingTagAviso = 'success';
      state.deleteTagAviso = action.payload;
    },
    [deleteTagAviso.rejected]: (state) => {
      state.isDeletingTagAviso = 'failed';
    },
    [getTagWorkflow.pending]: (state) => {
      state.isLoadingTagWorkflow = 'loading';
    },
    [getTagWorkflow.fulfilled]: (state, action) => {
      state.isLoadingTagWorkflow = 'success';
      state.tagWorkflow = action.payload;
    },
    [getTagWorkflow.rejected]: (state) => {
      state.isLoadingTagWorkflow = 'failed';
      state.tagWorkflow = [];
    },
    [postTagWorkflow.pending]: (state) => {
      state.isPostTagWorkflow = 'loading';
    },
    [postTagWorkflow.fulfilled]: (state, action) => {
      state.isPostTagWorkflow = 'success';
      state.postTagWorkflow = action.payload;
    },
    [postTagWorkflow.rejected]: (state) => {
      state.isPostTagWorkflow = 'failed';
    },
    [deleteTagWorkflow.pending]: (state) => {
      state.deleteTagWorkflowMessage = false;
      state.isDeletingTagWorkflow = 'loading';
    },
    [deleteTagWorkflow.fulfilled]: (state, action) => {
      state.isDeletingTagWorkflow = 'success';
      state.deleteTagWorkflowMessage = action.payload.message;
      state.deleteTagWorkflow = action.payload;
    },
    [deleteTagWorkflow.rejected]: (state) => {
      state.deleteTagWorkflowMessage = false;
      state.isDeletingTagWorkflow = 'failed';
    },
    [postTagNota.pending]: (state) => {
      state.isPostTagNota = 'loading';
    },
    [postTagNota.fulfilled]: (state, action) => {
      state.isPostTagNota = 'success';
      state.postTagNota = action.payload;
    },
    [postTagNota.rejected]: (state) => {
      state.isPostTagNota = 'failed';
    },
    [getTagValidation.pending]: (state) => {
      state.isGetTagValidation = 'loading';
    },
    [getTagValidation.fulfilled]: (state, action) => {
      state.isGetTagValidation = 'success';
      state.tagValidation = action.payload;
    },
    [getTagValidation.rejected]: (state) => {
      state.isGetTagValidation = 'failed';
    },
    [postTagExecucao.pending]: (state) => {
      state.isPostTagExecucao = 'loading';
    },
    [postTagExecucao.fulfilled]: (state, action) => {
      state.isPostTagExecucao = 'success';
      state.postTagExecucao = action.payload;
    },
    [postTagExecucao.rejected]: (state) => {
      state.isPostTagExecucao = 'failed';
    },
    [getTagExecucoes.pending]: (state) => {
      state.isLoadingTagExecucoes = 'loading';
    },
    [getTagExecucoes.fulfilled]: (state, action) => {
      state.isLoadingTagExecucoes = 'success';
      state.tagExecucoes = action.payload;
    },
    [getTagExecucoes.rejected]: (state) => {
      state.isLoadingTagExecucoes = 'failed';
    },
    [updateTagExecucao.pending]: (state) => {
      state.isUpdatingTagExecucao = 'loading';
    },
    [updateTagExecucao.fulfilled]: (state, action) => {
      state.isUpdatingTagExecucao = 'success';
      state.updateTagExecucao = action.payload;
    },
    [updateTagExecucao.rejected]: (state) => {
      state.isUpdatingTagExecucao = 'failed';
    },
    [deleteTagExecucao.pending]: (state) => {
      state.isDeletingTagExecucao = 'loading';
    },
    [deleteTagExecucao.fulfilled]: (state, action) => {
      state.isDeletingTagExecucao = 'success';
      state.deleteTagExecucao = action.payload;
    },
    [deleteTagExecucao.rejected]: (state) => {
      state.isDeletingTagExecucao = 'failed';
    },
    [getInvoiceOrderItems.pending]: (state) => {
      state.isInvoiceOrderItems = 'loading';
    },
    [getInvoiceOrderItems.fulfilled]: (state, action) => {
      state.isInvoiceOrderItems = 'success';
      state.invoiceOrderItems = action.payload;
    },
    [getInvoiceOrderItems.rejected]: (state) => {
      state.isInvoiceOrderItems = 'failed';
    },
    [getOrderByInvId.pending]: (state) => {
      state.isLoadingOrderByInvId = 'loading';
    },
    [getOrderByInvId.fulfilled]: (state, action) => {
      state.isLoadingOrderByInvId = 'success';
      state.orderByInvId = action.payload;
    },
    [getOrderByInvId.rejected]: (state) => {
      state.isLoadingOrderByInvId = 'failed';
    },
    [getInvOrder.pending]: (state) => {
      state.isLoadingInvOrder = 'loading';
    },
    [getInvOrder.fulfilled]: (state, action) => {
      state.isLoadingInvOrder = 'success';
      state.invOrder = action.payload;
    },
    [getInvOrder.rejected]: (state) => {
      state.isLoadingInvOrder = 'failed';
    },
    [getOrderItem.pending]: (state) => {
      state.isLoadingOrderItem = 'loading';
    },
    [getOrderItem.fulfilled]: (state, action) => {
      state.isLoadingOrderItem = 'success';
      state.orderItem = action.payload;
    },
    [getOrderItem.rejected]: (state) => {
      state.isLoadingOrderItem = 'failed';
    },
    [getOrderById.pending]: (state) => {
      state.isLoadingOrderById = 'loading';
    },
    [getOrderById.fulfilled]: (state, action) => {
      state.isLoadingOrderById = 'success';
      state.orderById = action.payload;
    },
    [getOrderById.rejected]: (state) => {
      state.isLoadingOrderById = 'failed';
    },
    [getOrderByCnpj.pending]: (state) => {
      state.isLoadingOrderByCnpj = 'loading';
    },
    [getOrderByCnpj.fulfilled]: (state, action) => {
      state.isLoadingOrderByCnpj = 'success';
      state.orderByCnpj = action.payload;
    },
    [getOrderByCnpj.rejected]: (state) => {
      state.isLoadingOrderByCnpj = 'failed';
    },
    [postInvoiceOrderConfirmation.pending]: (state) => {
      state.isPostInvoiceOrderConfirmation = 'loading';
    },
    [postInvoiceOrderConfirmation.fulfilled]: (state) => {
      state.isPostInvoiceOrderConfirmation = 'success';
    },
    [postInvoiceOrderConfirmation.rejected]: (state) => {
      state.isPostInvoiceOrderConfirmation = 'failed';
    },
    [postInvOrder.pending]: (state) => {
      state.isPostInvOrder = 'loading';
    },
    [postInvOrder.fulfilled]: (state, action) => {
      state.isPostInvOrder = 'success';
      state.postInvOrder = action.payload;
    },
    [postInvOrder.rejected]: (state) => {
      state.isPostInvOrder = 'failed';
    },
    [postInvOrderItem.pending]: (state) => {
      state.isPostInvOrderItem = 'loading';
    },
    [postInvOrderItem.fulfilled]: (state, action) => {
      state.isPostInvOrderItem = 'success';
      state.postInvOrderItem = action.payload;
    },
    [postInvOrderItem.rejected]: (state) => {
      state.isPostInvOrderItem = 'failed';
    },
    [postInvOrderByFile.pending]: (state) => {
      state.isPostInvOrderByFile = 'loading';
    },
    [postInvOrderByFile.fulfilled]: (state, action) => {
      state.isPostInvOrderByFile = 'success';
      state.postInvOrderByFile = action.payload;
    },
    [postInvOrderByFile.rejected]: (state) => {
      state.isPostInvOrderByFile = 'failed';
    },
    [updateInvOrder.pending]: (state) => {
      state.isUpdatingInvOrder = 'loading';
    },
    [updateInvOrder.fulfilled]: (state, action) => {
      state.isUpdatingInvOrder = 'success';
      state.updateInvOrder = action.payload;
    },
    [updateInvOrder.rejected]: (state) => {
      state.isUpdatingInvOrder = 'failed';
    },
    [updateInvOrderItem.pending]: (state) => {
      state.isUpdatingInvOrderItem = 'loading';
    },
    [updateInvOrderItem.fulfilled]: (state, action) => {
      state.isUpdatingInvOrderItem = 'success';
      state.updateInvOrderItem = action.payload;
    },
    [updateInvOrderItem.rejected]: (state) => {
      state.isUpdatingInvOrderItem = 'failed';
    },
    [deleteInvOrder.pending]: (state) => {
      state.isDeletingInvOrder = 'loading';
    },
    [deleteInvOrder.fulfilled]: (state, action) => {
      state.isDeletingInvOrder = 'success';
      state.deleteInvOrder = action.payload;
    },
    [deleteInvOrder.rejected]: (state) => {
      state.isDeletingInvOrder = 'failed';
    },
    [deleteInvOrderItem.pending]: (state) => {
      state.isDeletingInvOrderItem = 'loading';
    },
    [deleteInvOrderItem.fulfilled]: (state, action) => {
      state.isDeletingInvOrderItem = 'success';
      state.deleteInvOrderItem = action.payload;
    },
    [deleteInvOrderItem.rejected]: (state) => {
      state.isDeletingInvOrderItem = 'failed';
    },
    [postNotaCte.pending]: (state) => {
      state.isPostNotaCte = 'loading';
    },
    [postNotaCte.fulfilled]: (state, action) => {
      state.isPostNotaCte = 'success';
      state.notaCteBody = action.payload;
      state.successPostNotaCte.push({
        arq: action.meta.arg.nome,
        resul: action.type
      });
      state.successPostNotaCteQtd = state.successPostNotaCteQtd + 1;
    },
    [postNotaCte.rejected]: (state, action) => {
      state.isPostNotaCte = 'failed';
      state.failPostNotaCte.push({
        arq: action.meta.arg.nome,
        resul: action.type
      });
      state.failPostNotaQtdCte = state.failPostNotaQtdCte + 1;
    },
    [getReadActions.pending]: (state) => {
      state.isLoadingReadActions = 'loading';
    },
    [getReadActions.fulfilled]: (state, action) => {
      state.isLoadingReadActions = 'success';
      state.getReadActions = action.payload;
    },
    [getReadActions.rejected]: (state) => {
      state.isLoadingReadActions = 'failed';
    },
    [getReadActionsNota.pending]: (state) => {
      state.isLoadingReadActionsNota = 'loading';
    },
    [getReadActionsNota.fulfilled]: (state, action) => {
      state.isLoadingReadActionsNota = 'success';
      state.getReadActionsNota = action.payload;
    },
    [getReadActionsNota.rejected]: (state) => {
      state.isLoadingReadActionsNota = 'failed';
    },
    [postReadActions.pending]: (state) => {
      state.isPostReadActions = 'loading';
    },
    [postReadActions.fulfilled]: (state, action) => {
      state.isPostReadActions = 'success';
      state.postReadActions = action.payload;
    },
    [postReadActions.rejected]: (state) => {
      state.isPostReadActions = 'failed';
    },
    [postReadActionsNota.pending]: (state) => {
      state.isPostReadActionsNota = 'loading';
    },
    [postReadActionsNota.fulfilled]: (state, action) => {
      state.isPostReadActionsNota = 'success';
      state.postReadActionsNota = action.payload;
    },
    [postReadActionsNota.rejected]: (state) => {
      state.isPostReadActionsNota = 'failed';
    },
    [updateActionsNota.pending]: (state) => {
      state.isUpdatingActionsNota = 'loading';
    },
    [updateActionsNota.fulfilled]: (state, action) => {
      state.isUpdatingActionsNota = 'success';
      state.updateActionsNota = action.payload;
    },
    [updateActionsNota.rejected]: (state) => {
      state.isUpdatingActionsNota = 'failed';
    },
    [humanLoopValidateNcm.pending]: () => {
      null;
    },
    [humanLoopValidateNcm.fulfilled]: (state, action) => {
      state.ncmValidateResponse = action.payload;
    },
    [humanLoopValidateNcm.rejected]: () => {
      null;
    },
    [HumanLoopModifyTrainingData.pending]: () => {
      null;
    },
    [HumanLoopModifyTrainingData.fulfilled]: (state, action) => {
      state.ModifyTrainingDataResponse = action.payload;
    },
    [HumanLoopModifyTrainingData.rejected]: () => {
      null;
    }
  }
});

export const notaAvulsaSelector = (state) => state.notaAvulsaData;

export default notaAvulsaDataSlice.reducer;
